import {
  IonItem,
  IonItemGroup,
  IonList,
  IonListHeader,
  IonProgressBar,
  IonTitle,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { ChartDateProjectParameters } from "../../models/ChartObjects";
import { ProjectTimePerTaskGridData } from "../../models/Project";
import {
  getProjectTimePerTaskGridData,
  setProjectTimePerTaskGridLoading,
} from "../../data/projects/projects.actions";
import ProjectTaskTimeGridItem from "./ProjectTaskTimeGridItem";
import { connect } from "../../data/connect";

interface OwnProps {
  selectionStart: String;
  selectionEnd: String;
  projectId: String;
  hide: boolean;
}

interface StateProps {
  projectTimePerTaskGridData: ProjectTimePerTaskGridData[];
  loading: boolean;
}

interface DispatchProps {
  getProjectTimePerTaskGridData: typeof getProjectTimePerTaskGridData;
  setProjectTimePerTaskGridLoading: typeof setProjectTimePerTaskGridLoading;
}

interface ProjectTaskTimeGridProps
  extends OwnProps,
    StateProps,
    DispatchProps {}

const ProjectTaskTimeGrid: React.FC<ProjectTaskTimeGridProps> = ({
  selectionStart,
  selectionEnd,
  projectId,
  hide,
  projectTimePerTaskGridData,
  loading,
  getProjectTimePerTaskGridData,
  setProjectTimePerTaskGridLoading,
}) => {
  /*const [shoppingItemsList, setModifiedShoppingItemsList] = useState<
    ShoppingItem[]
  >(shoppingItems);*/

  useEffect(() => {
    /*(async function loadData() {
        await setProjectTimePerTaskGridLoading(true);
      if (projectId !== "") {
        //await getShoppingListItems(shoppingList);
        let chartDates = {
            startDate: selectionStart,
            endDate: selectionEnd,
            projectId: projectId,
          } as ChartDateProjectParameters;
        await getProjectTimePerTaskGridData(chartDates);
      }
    })();*/

    //setModifiedShoppingItemsList(shoppingItems);
    setProjectTimePerTaskGridLoading(false);
  }, [projectTimePerTaskGridData, projectId]); //, shoppingItems

  const refreshData = async () => {
    //setLoading(true);
    await setProjectTimePerTaskGridLoading(true);
    let chartDates = {
      startDate: selectionStart,
      endDate: selectionEnd,
      projectId: projectId,
    } as ChartDateProjectParameters;
    await getProjectTimePerTaskGridData(chartDates);
    //setLoading(false);
  };

  //console.log(shoppingItems);
  if (projectTimePerTaskGridData.length === 0 && !hide) {
    return (
      <IonList>
        <IonListHeader>No time activities found</IonListHeader>
      </IonList>
    );
  }

  if (loading) {
    return (
      <>
        <IonItem color="secondary">
          <IonTitle className="ion-text-wrap">Time per task details</IonTitle>
        </IonItem>
        <IonList>
          <IonListHeader>Loading...</IonListHeader>
          <IonProgressBar color="primary" type="indeterminate"></IonProgressBar>
        </IonList>
      </>
    );
  }

  return (
    <>
      <IonItem color="secondary">
        <IonTitle className="ion-text-wrap">Time per task details</IonTitle>
      </IonItem>
      <IonList style={hide ? { display: "none" } : {}}>
        {projectTimePerTaskGridData.map((data) => (
          <IonItemGroup key={`group-${data.activity}`}>
            <ProjectTaskTimeGridItem
              projectTimePerTaskGridData={data}
            ></ProjectTaskTimeGridItem>
          </IonItemGroup>
        ))}
      </IonList>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    projectTimePerTaskGridData: state.projectData.projectTimePerTaskGridData,
    loading: state.projectData.projectTimePerTaskGridLoading,
  }),
  mapDispatchToProps: {
    getProjectTimePerTaskGridData,
    setProjectTimePerTaskGridLoading,
  },
  component: ProjectTaskTimeGrid,
});
