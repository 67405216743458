import { getProjectData } from "../dataApi";
import { ActionType } from "../../utils/types";
import { ProjectsState } from "./projects.state";
import {
  Project,
  ProjectTimePerTaskGridData,
  ProjectTimePerSubTaskGridData,
  ProjectTaskList,
} from "../../models/Project";
import axios from "axios";
import {
  ChartData,
  ChartDateParameters,
  ChartDateProjectParameters,
} from "../../models/ChartObjects";

export const loadProjectData = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setLoading(true));
  const data = await getProjectData();
  dispatch(setData(data));
  dispatch(setLoading(false));
};

export const setLoading = (isLoading: boolean) =>
  ({
    type: "set-projects-loading",
    isLoading,
  } as const);

export const setData = (data: Partial<ProjectsState>) =>
  ({
    type: "set-projects-data",
    data,
  } as const);

export const addNewProject =
  (data: Project) => async (dispatch: React.Dispatch<any>) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      //console.info(data);
      const res = await axios.post("/api/projects", data, config);
      const result = await res.data; //.json();
      //console.info(result);

      dispatch(addProject(result));
    } catch {
      console.log("Error in projects.actions -> addNewProject");
    }
  };

export const editProject =
  (data: Project) => async (dispatch: React.Dispatch<any>) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      console.info(data);
      //console.info(data._id);
      const res = await axios.put(`/api/projects/${data._id}`, data, config);
      //console.info(data);
      /*const res = await fetch(`/api/projects/${data.id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });*/
      const result = await res.data; //.json();

      dispatch(updateProject(result));
    } catch (e) {
      console.log(e);
      console.log("Error in projects.actions -> editProject");
    }
  };

export const removeProject =
  (data: Project) => async (dispatch: React.Dispatch<any>) => {
    try {
      //console.info(data);
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.delete(`/api/projects/${data._id}`, config);
      const result = await res.data;
      //console.info(result);

      dispatch(deleteProject(data));
    } catch (Error) {
      //console.log(Error.response)
      //alert(Error.response.data);
      console.log("Error in projects.actions -> editProject");
    }
  };

export const getProject = (data: Project) =>
  ({
    type: "get-project",
    data,
  } as const);

export const addProject = (data: Project) =>
  ({
    type: "add-project",
    data,
  } as const);

export const updateProject = (data: Project) =>
  ({
    type: "update-project",
    data,
  } as const);

export const deleteProject = (data: Project) =>
  ({
    type: "delete-project",
    data,
  } as const);

export const getProjectTimeTrackedChartData =
  (data: ChartDateProjectParameters) =>
  async (dispatch: React.Dispatch<any>) => {
    try {
      //console.info(data);

      const res = await axios.get(
        `/api/projecttimetrackedchart/?startdate=${data.startDate}&enddate=${data.endDate}&projectid=${data.projectId}`
      );
      const result = await res.data; //json();

      //console.log(result);

      dispatch(setProjectTimeTrackedChartData(result));
    } catch {
      //alert(e.response.data);
      console.log("Error in items.actions -> getProjectTimeTrackedChartData");
    }
  };

export const setProjectTimeTrackedChartData = (chartData: ChartData) =>
  ({
    type: "set-project-time-tracked-chart-data",
    chartData,
  } as const);

export const setProjectTimeTrackedChartLoading = (loading: boolean) =>
  ({
    type: "set-project-time-tracked-chart-loading",
    loading,
  } as const);

export const getProjectTotalTimeTrackedChartData =
  (data: ChartDateProjectParameters) =>
  async (dispatch: React.Dispatch<any>) => {
    try {
      //console.info(data);

      const res = await axios.get(
        `/api/projecttotaltimetrackedchart/?projectid=${data.projectId}`
      );
      const result = await res.data; //json();

      //console.log(result);

      dispatch(setProjectTotalTimeTrackedChartData(result));
    } catch {
      //alert(e.response.data);
      console.log(
        "Error in items.actions -> getProjectTotalTimeTrackedChartData"
      );
    }
  };

export const setProjectTotalTimeTrackedChartData = (chartData: ChartData) =>
  ({
    type: "set-project-total-time-tracked-chart-data",
    chartData,
  } as const);

export const setProjectTotalTimeTrackedChartLoading = (loading: boolean) =>
  ({
    type: "set-project-total-time-tracked-chart-loading",
    loading,
  } as const);

export const getProjectProfitLossChartData =
  (data: ChartDateProjectParameters) =>
  async (dispatch: React.Dispatch<any>) => {
    try {
      //console.info(data);

      const res = await axios.get(
        `/api/projectprofitlosschart/?startdate=${data.startDate}&enddate=${data.endDate}&projectid=${data.projectId}`
      );
      const result = await res.data; //json();

      //console.log(result);

      dispatch(setProjectProfitLossChartData(result));
    } catch {
      //alert(e.response.data);
      console.log("Error in items.actions -> getProjectProfitLossChartData");
    }
  };

export const setProjectProfitLossChartData = (chartData: ChartData) =>
  ({
    type: "set-project-profit-loss-chart-data",
    chartData,
  } as const);

export const setProjectProfitLossChartLoading = (loading: boolean) =>
  ({
    type: "set-project-profit-loss-chart-loading",
    loading,
  } as const);

export const getProjectProfitLossPerHourChartData =
  (data: ChartDateProjectParameters) =>
  async (dispatch: React.Dispatch<any>) => {
    try {
      //console.info(data);

      const res = await axios.get(
        `/api/projectprofitlossperhourchart/?projectid=${data.projectId}`
      );
      const result = await res.data; //json();

      //console.log(result);

      dispatch(setProjectProfitLossPerHourChartData(result));
    } catch {
      //alert(e.response.data);
      console.log(
        "Error in items.actions -> getProjectProfitLossPerHourChartData"
      );
    }
  };

export const setProjectProfitLossPerHourChartData = (chartData: ChartData) =>
  ({
    type: "set-project-profit-loss-per-hour-chart-data",
    chartData,
  } as const);

export const setProjectProfitLossPerHourChartLoading = (loading: boolean) =>
  ({
    type: "set-project-profit-loss-per-hour-chart-loading",
    loading,
  } as const);

export const getProjectTimePerTaskChartData =
  (data: ChartDateProjectParameters) =>
  async (dispatch: React.Dispatch<any>) => {
    try {
      //console.info(data);

      const res = await axios.get(
        `/api/projecttimepertaskchart/?startdate=${data.startDate}&enddate=${data.endDate}&projectid=${data.projectId}`
      );
      const result = await res.data; //json();

      //console.log(result);

      dispatch(setProjectTimePerTaskChartData(result));
    } catch {
      //alert(e.response.data);
      console.log("Error in items.actions -> getProjectTimePerTaskChartData");
    }
  };

export const setProjectTimePerTaskChartData = (chartData: ChartData) =>
  ({
    type: "set-project-time-per-task-chart-data",
    chartData,
  } as const);

export const setProjectTimePerTaskChartLoading = (loading: boolean) =>
  ({
    type: "set-project-time-per-task-chart-loading",
    loading,
  } as const);

export const getProjectTimePerProjectChartData =
  () => async (dispatch: React.Dispatch<any>) => {
    try {
      //console.info(data);

      const res = await axios.get(`/api/projecttimeperprojectchart/`);
      const result = await res.data; //json();

      //console.log(result);

      dispatch(setProjectTimePerProjectChartData(result));
    } catch {
      //alert(e.response.data);
      console.log(
        "Error in items.actions -> getProjectTimePerProjectChartData"
      );
    }
  };

export const setProjectTimePerProjectChartData = (chartData: ChartData) =>
  ({
    type: "set-project-time-per-project-chart-data",
    chartData,
  } as const);

export const setProjectTimePerProjectChartLoading = (loading: boolean) =>
  ({
    type: "set-project-time-per-project-chart-loading",
    loading,
  } as const);

export const getProjectTimePerTaskGridData =
  (data: ChartDateProjectParameters) =>
  async (dispatch: React.Dispatch<any>) => {
    try {
      //console.info(data);

      const res = await axios.get(
        `/api/projecttimepertaskgrid/?startdate=${data.startDate}&enddate=${data.endDate}&projectid=${data.projectId}`
      );
      const result = await res.data; //json();

      //console.log(result);

      dispatch(setProjectTimePerTaskGridData(result));
    } catch {
      //alert(e.response.data);
      console.log("Error in items.actions -> getProjectTimePerTaskGridData");
    }
  };

export const setProjectTimePerTaskGridData = (
  projectTimePerTaskGridData: ProjectTimePerTaskGridData[]
) =>
  ({
    type: "set-project-time-per-task-grid-data",
    projectTimePerTaskGridData,
  } as const);

export const setProjectTimePerTaskGridLoading = (loading: boolean) =>
  ({
    type: "set-project-time-per-task-grid-loading",
    loading,
  } as const);

export const getProjectTimePerSubTaskGridData =
  (data: ChartDateProjectParameters) =>
  async (dispatch: React.Dispatch<any>) => {
    try {
      //console.info(data);

      const res = await axios.get(
        `/api/projecttimepersubtaskgrid/?startdate=${data.startDate}&enddate=${data.endDate}&projectid=${data.projectId}`
      );
      const result = await res.data; //json();

      //console.log(result);

      dispatch(setProjectTimePerSubTaskGridData(result));
    } catch {
      //alert(e.response.data);
      console.log("Error in items.actions -> getProjectTimePerSubTaskGridData");
    }
  };

export const setProjectTimePerSubTaskGridData = (
  projectTimePerSubTaskGridData: ProjectTimePerSubTaskGridData[]
) =>
  ({
    type: "set-project-time-per-sub-task-grid-data",
    projectTimePerSubTaskGridData,
  } as const);

export const setProjectTimePerSubTaskGridLoading = (loading: boolean) =>
  ({
    type: "set-project-time-per-sub-task-grid-loading",
    loading,
  } as const);

export const getProjectTaskListData =
  () => async (dispatch: React.Dispatch<any>) => {
    try {
      //console.info(data);

      const res = await axios.get(`/api/projecttasklist/`);
      const result = await res.data; //json();

      //console.log(result);

      dispatch(setProjectTaskListData(result));
    } catch {
      //alert(e.response.data);
      console.log("Error in items.actions -> getProjectTaskListData");
    }
  };

export const setProjectTaskListData = (projectTaskList: ProjectTaskList[]) =>
  ({
    type: "set-project-task-list-data",
    projectTaskList,
  } as const);

export const getProjectFinanceTaskListData =
  () => async (dispatch: React.Dispatch<any>) => {
    try {
      //console.info(data);

      const res = await axios.get(`/api/projectfinancetasklist/`);
      const result = await res.data; //json();

      //console.log(result);

      dispatch(setProjectFinanceTaskListData(result));
    } catch {
      //alert(e.response.data);
      console.log("Error in items.actions -> getProjectFinanceTaskListData");
    }
  };

export const setProjectFinanceTaskListData = (
  projectTaskList: ProjectTaskList[]
) =>
  ({
    type: "set-project-finance-task-list-data",
    projectTaskList,
  } as const);

export const updateFilteredItems = (filteredItems: string[]) =>
  ({
    type: "update-filtered-items",
    filteredItems,
  } as const);

export const setSearchText = (searchText?: string) =>
  ({
    type: "set-search-text",
    searchText,
  } as const);

export type ProjectsActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setData>
  | ActionType<typeof getProject>
  | ActionType<typeof addProject>
  | ActionType<typeof updateProject>
  | ActionType<typeof deleteProject>
  | ActionType<typeof setProjectTimeTrackedChartData>
  | ActionType<typeof setProjectTimeTrackedChartLoading>
  | ActionType<typeof setProjectTotalTimeTrackedChartData>
  | ActionType<typeof setProjectTotalTimeTrackedChartLoading>
  | ActionType<typeof setProjectProfitLossChartData>
  | ActionType<typeof setProjectProfitLossChartLoading>
  | ActionType<typeof setProjectProfitLossPerHourChartData>
  | ActionType<typeof setProjectProfitLossPerHourChartLoading>
  | ActionType<typeof setProjectTimePerTaskChartData>
  | ActionType<typeof setProjectTimePerTaskChartLoading>
  | ActionType<typeof setProjectTimePerProjectChartData>
  | ActionType<typeof setProjectTimePerProjectChartLoading>
  | ActionType<typeof setProjectTimePerTaskGridData>
  | ActionType<typeof setProjectTimePerTaskGridLoading>
  | ActionType<typeof setProjectTimePerSubTaskGridData>
  | ActionType<typeof setProjectTimePerSubTaskGridLoading>
  | ActionType<typeof setProjectTaskListData>
  | ActionType<typeof setProjectFinanceTaskListData>
  | ActionType<typeof updateFilteredItems>
  | ActionType<typeof setSearchText>;
