import { FinanceActivitiesState } from "./financeActivities.state";
import { FinanceActivity } from "../../models/FinanceActivity";
import { Project } from "../../models/Project";

import { ActionType } from "../../utils/types";
import axios from "axios";

export const setLoading = (isLoading: boolean) =>
  ({
    type: "set-financeActivities-loading",
    isLoading,
  } as const);

export const getFinanceActivities =
  (data: Project) => async (dispatch: React.Dispatch<any>) => {
    try {
      //console.info(data);
      if (data._id !== "") {
        const res = await axios.get(`/api/financeactivities/${data._id}`);
        const result = await res.data; //json();

        //console.log(result);

        dispatch(setData(result));
      }
    } catch {
      console.log("Error in financeActivites.actions -> getFinanceActivities");
    }
  };

export const addNewFinanceActivity =
  (data: FinanceActivity) => async (dispatch: React.Dispatch<any>) => {
    try {
      //console.info(data);

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post("/api/financeactivities", data, config);
      const result = await res.data; //json();

      //console.log(result);

      dispatch(addFinanceActivity(result));
    } catch {
      console.log(
        "Error in financeActivities.actions -> addNewFinanceActivity"
      );
    }
  };

export const editFinanceActivity =
  (data: FinanceActivity) => async (dispatch: React.Dispatch<any>) => {
    try {
      //console.info(data);
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.put(
        `/api/financeActivities/${data._id}`,
        data,
        config
      );
      const result = await res.data;
      //console.info(result);

      dispatch(updateFinanceActivity(result));
    } catch {
      console.log("Error in financeActivities.actions -> editFinanceActivity");
    }
  };

export const removeFinanceActivity =
  (data: FinanceActivity) => async (dispatch: React.Dispatch<any>) => {
    try {
      //console.info(data);
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.delete(
        `/api/financeActivities/${data._id}`,
        config
      );
      /*const result = */ await res.data;
      //console.info(result);

      dispatch(deleteFinanceActivity(data));
    } catch {
      console.log(
        "Error in financeActivities.actions -> removeFinanceActivity"
      );
    }
  };

export const setData = (data: FinanceActivity[]) =>
  ({
    type: "set-financeActivities-data",
    data,
  } as const);

export const getFinanceActivity = (data: FinanceActivity[]) =>
  ({
    type: "get-financeActivity",
    data,
  } as const);

export const addFinanceActivity = (data: FinanceActivity) =>
  ({
    type: "add-financeActivity",
    data,
  } as const);

export const updateFinanceActivity = (data: FinanceActivity) =>
  ({
    type: "update-financeActivity",
    data,
  } as const);

export const deleteFinanceActivity = (data: FinanceActivity) =>
  ({
    type: "delete-financeActivity",
    data,
  } as const);

export type FinanceActivitiesActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setData>
  | ActionType<typeof getFinanceActivity>
  | ActionType<typeof addFinanceActivity>
  | ActionType<typeof updateFinanceActivity>
  | ActionType<typeof deleteFinanceActivity>;
