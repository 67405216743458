import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import {
  IonButton,
  IonItem,
  IonLabel,
  IonTitle,
  IonProgressBar,
} from "@ionic/react";
import {
  ChartDataset,
  ChartData,
  ChartDateProjectParameters,
} from "../../models/ChartObjects";
import {
  getProjectTotalTimeTrackedChartData,
  setProjectTotalTimeTrackedChartLoading,
} from "../../data/projects/projects.actions";
import { connect } from "../../data/connect";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineController,
  LineElement,
  BarController,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ChartData as ChartJSData,
  //ChartTypeRegistry,
} from "chart.js";
import { Chart, Line } from "react-chartjs-2";

interface OwnProps {
  projectId: String;
}

interface StateProps {
  projectTotalTimeTrackedChartData: ChartData;
  projectTotalTimeTrackedChartLoading?: boolean;
}

interface DispatchProps {
  getProjectTotalTimeTrackedChartData: typeof getProjectTotalTimeTrackedChartData;
  setProjectTotalTimeTrackedChartLoading: typeof setProjectTotalTimeTrackedChartLoading;
}

interface ProjectTotalTimeTrackedChartProps
  extends OwnProps,
    StateProps,
    DispatchProps {}

const ProjectTotalTimeTrackedChart: React.FC<
  ProjectTotalTimeTrackedChartProps
> = ({
  projectId,
  projectTotalTimeTrackedChartData,
  projectTotalTimeTrackedChartLoading,
  getProjectTotalTimeTrackedChartData,
  setProjectTotalTimeTrackedChartLoading,
}) => {
  const [labels, setLabels] = useState<String[]>([]);
  const [datasets, setDatasets] = useState<ChartDataset[]>([]);
  const [chartData, setChartData] = useState<ChartJSData[]>([]);
  //const [loading, setLoading] = useState(false);
  const chartRef = useRef<ChartJS>(null);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineController,
    LineElement,
    BarController,
    BarElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

  //var loading = false;
  //console.log(loading);

  //var data = [] as Number[];

  useEffect(() => {
    //setLoading(true);
    const chart = chartRef.current;

    getData();
    //setLoading(false);
    setProjectTotalTimeTrackedChartLoading(false);
  }, [projectTotalTimeTrackedChartData, projectId]);

  useLayoutEffect(() => {
    if (chartRef !== null && chartRef.current !== null) {
      //chartRef.current.chartInstance.update();
      chartRef.current.update();
    }
  }, [datasets, labels]);

  var dynamicColors = function (alpha: number) {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    //var a = Math.random() * 1;
    return "rgb(" + r + "," + g + "," + b + ", " + alpha + ")";
  };

  const refreshData = async () => {
    //setLoading(true);
    await setProjectTotalTimeTrackedChartLoading(true);
    let chartDates = {
      startDate: "",
      endDate: "",
      projectId: projectId,
    } as ChartDateProjectParameters;
    await getProjectTotalTimeTrackedChartData(chartDates);
    //setLoading(false);
  };

  const getData = async () => {
    setLabels(projectTotalTimeTrackedChartData.labels);
    setDatasets(projectTotalTimeTrackedChartData.datasets);

    //let data = {} as ChartJSData;

    if (chartRef !== null && chartRef.current !== null) {
      chartRef.current.update();
      chartRef.current.resize();
    }
  };

  const dataTest = {
    //"line",
    //type: "line" as const,
    //type: "line" as ChartTypeRegistry,
    datasets: datasets,
    labels: labels,
  }; //as ChartJSData;

  //console.log(projectTotalTimeTrackedChartData);
  const datasetsJS = [] as ChartDataset[];

  datasets.forEach((ds) => {
    datasetsJS.push({
      label: ds.label,
      data: ds.data,
      fill: true,
      borderWidth: ds.borderWidth,
      borderColor: ds.borderColor ? ds.borderColor : "rgb(53, 162, 235)",
      backgroundColor: ds.backgroundColor
        ? ds.backgroundColor
        : "rgba(53, 162, 235, 0.5)",
    } as ChartDataset);
  });

  const dataJS = {
    labels,
    datasets: datasetsJS /*[
      {
        label: datasets[0] ? datasets[0].label : "",
        data: datasets[0] ? datasets[0].data : "",
        borderColor: datasets[0]
          ? datasets[0].borderColor
          : "rgb(53, 162, 235)",
        backgroundColor: datasets[0]
          ? datasets[0].backgroundColor
          : "rgba(53, 162, 235, 0.5)",
      },
    ],*/,
  } as ChartJSData;

  //setChartData({datasets, labels});

  const options = {
    title: {
      text: "Total time tracked",
      display: true,
    },
    responsive: true,
    //maintainAspectRatio: false,
    //aspectRatio: 0.5,
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
          labelString: "Hours",
        },
      ],
    },
    animation: {
      duration: 0,
    },
  };

  return (
    <div /*style={{position: "relative", height:"40vh", width:"80vw"}}*/>
      <IonItem color="secondary">
        <IonTitle className="ion-text-wrap">Total time tracked</IonTitle>
        <IonButton
          size="small"
          shape="round"
          onClick={async () => await refreshData()}
        >
          <IonLabel>Change colours</IonLabel>
        </IonButton>
      </IonItem>
      {projectTotalTimeTrackedChartLoading ? (
        <IonItem>
          <IonTitle>Loading...</IonTitle>
          <IonProgressBar color="primary" type="indeterminate"></IonProgressBar>
        </IonItem>
      ) : (
        <Chart type="bar" data={dataJS} /*options={options}*/ redraw />
      )}
    </div>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    projectTotalTimeTrackedChartData:
      state.projectData.projectTotalTimeTrackedChartData,
    projectTotalTimeTrackedChartLoading:
      state.projectData.projectTotalTimeTrackedChartLoading,
  }),
  mapDispatchToProps: {
    getProjectTotalTimeTrackedChartData,
    setProjectTotalTimeTrackedChartLoading,
  },
  component: React.memo(ProjectTotalTimeTrackedChart),
});
