import React, { useState, useEffect } from "react";
import {
  IonRow,
  IonItemGroup,
  IonAlert,
  IonIcon,
  IonCol,
  IonButton,
} from "@ionic/react";
import { trashOutline, pencilOutline } from "ionicons/icons";
import { TimeActivity } from "../../models/TimeActivity";
import { User } from "../../models/User";
import { removeTimeActivity } from "../../data/timeActivities/timeActivities.actions";
import { connect } from "../../data/connect";
//import { RouteComponentProps } from "react-router";'
import { useHistory } from "react-router-dom";
import {
  formatDate,
  formatTime,
  formatDuration,
  formatFullDuration,
} from "../../utils/dateTimeFormat";

interface OwnProps {
  //extends RouteComponentProps {
  timeActivity: TimeActivity;
}

interface StateProps {
  user: User;
}

interface DispatchProps {
  removeTimeActivity: typeof removeTimeActivity;
}

interface TimeActivityItemProps extends OwnProps, StateProps, DispatchProps {}

const TimeActivityItem: React.FC<TimeActivityItemProps> = ({
  timeActivity,
  user,
  removeTimeActivity,
}) => {
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  let history = useHistory();

  useEffect(() => {
    /*if (shoppingItems.length !== 0 ||
      shoppingItems[0].timeActivityId === timeActivity._id) {

      }*/
    //getShoppingItems(timeActivity);
    //setLocalShoppingItems(shoppingItems);
    //getTotalSpent();
  }, [timeActivity]);

  return (
    <IonItemGroup>
      <IonRow>
        <IonCol size="2" sizeSm="2">
          {formatDate(timeActivity.date)}
        </IonCol>
        <IonCol size="5" sizeSm="3">
          {timeActivity.activity}
        </IonCol>
        <IonCol sizeSm="2" className="ion-hide-sm-down">
          {timeActivity.description}
        </IonCol>
        <IonCol size="1" className="ion-hide-sm-down">
          {formatTime(timeActivity.startTime)}
        </IonCol>
        <IonCol size="1" className="ion-hide-sm-down">
          {formatTime(timeActivity.endTime)}
        </IonCol>
        <IonCol size="3" sizeSm="1">
          {formatDuration(timeActivity.duration)}
        </IonCol>
        <IonCol size="2">
          <IonButton
            size="small"
            shape="round"
            fill="clear"
            routerLink={`/timeactivity/${timeActivity._id}`}
            routerDirection="none"
          >
            <IonIcon slot="icon-only" icon={pencilOutline} />
          </IonButton>
          <IonButton
            size="small"
            shape="round"
            fill="clear"
            onClick={() => {
              setShowDeleteAlert(true);
            }}
          >
            <IonIcon slot="icon-only" icon={trashOutline} />
          </IonButton>
        </IonCol>
      </IonRow>
      <IonAlert
        isOpen={showDeleteAlert}
        onDidDismiss={() => setShowDeleteAlert(false)}
        header={"Confirm!"}
        message={"<strong>Are you sure you want to delete this?</strong>!!!"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "Okay",
            handler: () => {
              removeTimeActivity(timeActivity);
            },
          },
        ]}
      />
    </IonItemGroup>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.user.user,
  }),
  mapDispatchToProps: {
    removeTimeActivity,
  },
  component: React.memo(TimeActivityItem),
});
