import { IonItemGroup, IonList, IonListHeader } from "@ionic/react";
import React from "react";
import { Project } from "../../models/Project";
import ProjectItem from "./ProjectItem";
import { connect } from "../../data/connect";

interface OwnProps {
  projects: Project[];
  hide: boolean;
}

interface StateProps {}

interface DispatchProps {}

interface ProjectListProps extends OwnProps, StateProps, DispatchProps {}

const ProjectList: React.FC<ProjectListProps> = ({ projects, hide }) => {
  //console.log(projects);
  if (projects.length === 0 && !hide) {
    return (
      <IonList>
        <IonListHeader>No Projects Found</IonListHeader>
      </IonList>
    );
  }

  return (
    <>
      <IonList style={hide ? { display: "none" } : {}}>
        {projects.map((project, index: number) => (
          <IonItemGroup key={`group-${index}`}>
            <ProjectItem project={project}></ProjectItem>
          </IonItemGroup>
        ))}
      </IonList>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: ProjectList,
});
