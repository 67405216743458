import React, { useEffect, useState } from "react";
import { IonChip, IonLabel } from "@ionic/react";
import EmbedChartModal from "./EmbedChartModal";

interface OwnProps {
  type: string;
  userId: string;
  projectId: String;
  chartType: string;
}

interface EmbedChartControlProps extends OwnProps {}

export const EmbedChartControl: React.FC<EmbedChartControlProps> = ({
  type,
  userId,
  projectId,
  chartType,
}) => {
  useEffect(() => {}, []);

  const [showModal, setShowModal] = useState(false);

  const onClicked = () => {
    setShowModal(!showModal);
  };

  return (
    <IonChip color="primary" onClick={onClicked}>
      <IonLabel color="white">{"<Embed>"}</IonLabel>
      <EmbedChartModal
        show={showModal}
        onClose={() => setShowModal(false)}
        type={type}
        userId={userId}
        projectId={projectId}
        chartType={chartType}
      />
    </IonChip>
  );
};
