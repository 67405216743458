import { TimeActivitiesActions } from "./timeActivities.actions";
import { TimeActivitiesState } from "./timeActivities.state";

export const timeActivitiesReducer = (
  state: TimeActivitiesState,
  action: TimeActivitiesActions
): TimeActivitiesState => {
  switch (action.type) {
    case "set-timeActivities-loading": {
      return { ...state, loading: action.isLoading };
    }
    case "set-timeActivities-data": {
      return { ...state, timeActivities: [...action.data] };
    }
    case "get-timeActivity": {
      return { ...state }; //currentStore: action.data };
    }
    /*case "set-current-timeActivity": {
      return { ...state, currentTimeActivity: action.data };
    }*/
    case "add-timeActivity": {
      return {
        ...state,
        timeActivities: [action.data, ...state.timeActivities],
      };
    }
    case "update-timeActivity": {
      return {
        ...state,
        timeActivities: state.timeActivities.map((timeActivity) =>
          timeActivity._id === action.data._id ? action.data : timeActivity
        ),
      };
    }
    case "delete-timeActivity": {
      return {
        ...state,
        timeActivities: state.timeActivities.filter(
          (timeActivity) => timeActivity._id !== action.data._id
        ),
      };
    }
  }
};
