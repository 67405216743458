import React, { useState, useEffect } from "react";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
} from "@ionic/react";
import "./Login.scss";
import { LoginDetails } from "../models/User";
import { login, clearError } from "../data/user/user.actions";
//import { useAuth, login, clearErrors } from "../data/user/AuthState";
import { connect } from "../data/connect";
import { Footer } from "../components/layout/Footer";
import { useLocation, RouteComponentProps } from "react-router";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {
  login: typeof login;
  clearError: typeof clearError;
}

interface StateProps {
  isAuthenticated: boolean;
  error: string;
}

interface LoginProps extends OwnProps, DispatchProps, StateProps {}

const Login: React.FC<LoginProps> = ({
  login,
  clearError,
  isAuthenticated,
  error,
  history,
}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [loginDetails, setLoginDetails] = useState<LoginDetails>({
    email: "",
    password: "",
  });
  const location = useLocation();
  //console.log(location);

  const email = loginDetails.email;
  const password = loginDetails.password;

  useEffect(() => {
    if (isAuthenticated) {
      //if (history.length > 1) history.goBack();
      //.go(1);
      //history.length - 2);
      //else
      history.push("/");
    }

    if (error === "Invalid Credentials") {
      clearError();
    }
  }, [error, isAuthenticated, history]);

  const onChange = (fieldName: string, fieldValue: string) => {
    setLoginDetails({ ...loginDetails, [fieldName]: fieldValue });
  };

  const loginUser = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!email) {
      setEmailError(true);
    }
    if (!password) {
      setPasswordError(true);
    }

    if (email && password) {
      await login(loginDetails);
      history.push("/dashboard", { direction: "none" });
    }
  };

  return (
    <IonPage id="login-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Login</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="login-logo">
          <img src="assets/icon/Logo.png" alt="Metric Soup logo" />
        </div>

        <form noValidate onSubmit={loginUser}>
          <IonList>
            <IonItem>
              <IonLabel position="stacked" color="primary">
                Email
              </IonLabel>
              <IonInput
                name="email"
                type="email"
                value={email}
                spellCheck={false}
                autocapitalize="off"
                onIonChange={(e) => onChange("email", e.detail.value!)}
                required
              ></IonInput>
            </IonItem>

            {formSubmitted && emailError && (
              <IonText color="danger">
                <p className="ion-padding-start">Email is required</p>
              </IonText>
            )}

            <IonItem>
              <IonLabel position="stacked" color="primary">
                Password
              </IonLabel>
              <IonInput
                name="password"
                type="password"
                value={password}
                onIonChange={(e) => onChange("password", e.detail.value!)}
              ></IonInput>
            </IonItem>

            {formSubmitted && passwordError && (
              <IonText color="danger">
                <p className="ion-padding-start">Password is required</p>
              </IonText>
            )}
          </IonList>

          <IonRow>
            <IonCol>
              <IonButton type="submit" expand="block">
                Login
              </IonButton>
            </IonCol>
            <IonCol>
              <IonButton routerLink="/signup" color="light" expand="block">
                Signup
              </IonButton>
            </IonCol>
          </IonRow>
        </form>
      </IonContent>
      <Footer hide={false} />
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    isAuthenticated: state.user.isAuthenticated,
    error: state.user.error,
  }),
  mapDispatchToProps: {
    login,
    clearError,
  },
  component: Login,
});
