import React, { useState, useEffect } from "react";
import { IonAlert } from "@ionic/react";
import { connect } from "../../data/connect";
import { maxProjects, maxItems } from "../../data/premiumChecks";
import { useHistory } from "react-router-dom";

interface OwnProps {
  show: boolean;
  onClosed: () => void;
  type: string;
}

interface StateProps {}

interface DispatchProps {}

interface PremiumAlertProps extends OwnProps, StateProps, DispatchProps {}

const PremiumAlert: React.FC<PremiumAlertProps> = ({
  show,
  onClosed,
  type,
}) => {
  const [showPremiumAlert, setShowPremiumAlert] = useState(show);
  const [message, setMessage] = useState("");

  let history = useHistory();

  useEffect(() => {
    setShowPremiumAlert(show);

    switch (type) {
      case "projects":
        setMessage(`add more than ${maxProjects} projects`);
        break;
      case "items":
        setMessage(`add more than ${maxItems} items`);
        break;
    }
  }, [show, type]);

  const close = () => {
    setShowPremiumAlert(false);
    onClosed();
  };

  const upgrade = () => {
    history.push(`/subscribe`);
    close();
  };

  return (
    <>
      <IonAlert
        isOpen={showPremiumAlert}
        onDidDismiss={() => close()}
        message={`Please upgrade to premium to ${message}`}
        buttons={[{ text: "Upgrade", handler: (d) => upgrade() }, "OK"]}
      />
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: React.memo(PremiumAlert),
});
