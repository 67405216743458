import React from "react";
import {
  IonContent,
  IonPage,
  IonTitle,
  IonHeader,
  IonToolbar,
} from "@ionic/react";
import { connect } from "../data/connect";

interface OwnProps {}

interface StateProps {}

interface DispatchProps {}

interface ContainerProps extends OwnProps, StateProps, DispatchProps {}

const ChangeLog: React.FC<ContainerProps> = () => {
  const main = async () => {
    const content = document.querySelector("ion-content.scrollbarSettings");
    const styles = document.createElement("style");

    styles.textContent = `
      ::-webkit-scrollbar {
        width: 5px;
      }
  
      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: 0 0 5px grey; 
        border-radius: 10px;
      }
  
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: lightgrey; 
        border-radius: 10px;
      }
  
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #bdc9bd; 
      }
    `;
    if (content !== null && content.shadowRoot !== null) {
      content.shadowRoot.appendChild(styles);
    }
  };

  setTimeout(() => {
    main();
  }, 250);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="secondary">
          <IonTitle>Change Log</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent class="scrollbarSettings">
        <IonTitle>Latest changes:</IonTitle> <br />
        25/04/2023: <br />
        - Redesigned project details page <br />
        <br />
        28/03/2023: <br />
        - Added auto suggestions to financial activities as well <br />
        <br />
        27/03/2023: <br />
        - Added auto suggestions to tasks and sub tasks <br />
        <br />
        10/02/2023: <br />
        - Routing bug fixed when pasting in a link <br />
        <br />
        21/11/2022: <br />
        - Fixed some financial activity bugs <br />
        <br />
        15/11/2022: <br />
        - Fixed logging in and out bug <br />
        - Minor cosmetic changes <br />
        <br />
        12/11/2022: <br />
        - Adjusted colour scheme <br />
        <br />
        02/11/2022: <br />
        - Adjusted project details page design <br />
        <br />
        01/11/2022: <br />
        - Fixed timer bug <br />
        - Adjusted project list design <br />
        <br />
        27/10/2022: <br />
        - Fixed time tracking bugs <br />
        <br />
        20/10/2022: <br />
        - Fixed time button issue <br />
        - Updated colour scheme <br />
        <br />
        16/07/2022: <br />
        - Added embedding for the profit/loss charts <br />
        <br />
        14/06/2022: <br />
        - Added Time per task details grid <br />
        - Added temporary logo as well as updated colour scheme (Will change in
        future) <br />
        <br />
        28/05/2022: <br />
        - Added an option to enable the embedding of charts per project. This
        allows the user to only enable it for certain projects <br />
        <br />
        26/05/2022: <br />
        - Added an option to enable the embedding of charts. This allows the
        user to disable the option so that their charts can't be embedded <br />
        <br />
        14/05/2022: <br />
        - Settings page added <br />
        - Users can now download their data <br />
        - Change log added (Never too late, right?) <br />
        <br />
        27/12/2021 - 07/05/2022: <br />
        - Lots and lots of exciting things <br />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: ChangeLog,
});
