import React, { useState, useEffect } from "react";
import {
  IonRow,
  IonItemGroup,
  IonAlert,
  IonIcon,
  IonCol,
  IonButton,
} from "@ionic/react";
import { trashOutline, pencilOutline } from "ionicons/icons";
import { FinanceActivity } from "../../models/FinanceActivity";
import { User } from "../../models/User";
import { removeFinanceActivity } from "../../data/financeActivities/financeActivities.actions";
import { connect } from "../../data/connect";
//import { RouteComponentProps } from "react-router";'
import { useHistory } from "react-router-dom";
import { formatDate } from "../../utils/dateTimeFormat";

interface OwnProps {
  //extends RouteComponentProps {
  financeActivity: FinanceActivity;
}

interface StateProps {
  user: User;
}

interface DispatchProps {
  removeFinanceActivity: typeof removeFinanceActivity;
}

interface FinanceActivityItemProps
  extends OwnProps,
    StateProps,
    DispatchProps {}

const FinanceActivityItem: React.FC<FinanceActivityItemProps> = ({
  financeActivity,
  user,
  removeFinanceActivity,
}) => {
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  let history = useHistory();

  useEffect(() => {
    /*if (shoppingItems.length !== 0 ||
      shoppingItems[0].financeActivityId === financeActivity._id) {

      }*/
    //getShoppingItems(financeActivity);
    //setLocalShoppingItems(shoppingItems);
    //getTotalSpent();
  }, [financeActivity]);

  return (
    <IonItemGroup>
      <IonRow>
        <IonCol size="2" sizeSm="2">
          {formatDate(financeActivity.date)}
        </IonCol>
        <IonCol size="3" sizeSm="3">
          {financeActivity.activity}
        </IonCol>
        <IonCol sizeSm="2" className="ion-hide-sm-down">
          {financeActivity.description}
        </IonCol>
        <IonCol size="3" sizeSm="1">
          {new Intl.NumberFormat(`${user.numberFormat}`, {
            style: "currency",
            currency: `${user.currency}`,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(
            financeActivity.expense
              ? -financeActivity.amount
              : financeActivity.amount
          )}
        </IonCol>
        <IonCol size="2" className="ion-hide-sm-down">
          {financeActivity.notes}
        </IonCol>

        <IonCol size="2">
          <IonButton
            size="small"
            shape="round"
            fill="clear"
            routerLink={`/financeactivity/${financeActivity._id}`}
            routerDirection="none"
          >
            <IonIcon slot="icon-only" icon={pencilOutline} />
          </IonButton>
          <IonButton
            size="small"
            shape="round"
            fill="clear"
            onClick={() => {
              setShowDeleteAlert(true);
            }}
          >
            <IonIcon slot="icon-only" icon={trashOutline} />
          </IonButton>
        </IonCol>
      </IonRow>
      <IonAlert
        isOpen={showDeleteAlert}
        onDidDismiss={() => setShowDeleteAlert(false)}
        header={"Confirm!"}
        message={"<strong>Are you sure you want to delete this?</strong>!!!"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "Okay",
            handler: () => {
              removeFinanceActivity(financeActivity);
            },
          },
        ]}
      />
    </IonItemGroup>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.user.user,
  }),
  mapDispatchToProps: {
    removeFinanceActivity,
  },
  component: React.memo(FinanceActivityItem),
});
