import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
//import { RouteComponentProps, useParams } from "react-router";
//import Page from "../../pages/Page";
import Dashboard from "../../pages/Dashboard";
//import Tutorial from "../../pages/Tutorial";
import Subscribe from "../../pages/Subscribe";
import Settings from "../../pages/Settings";
import ChangeLog from "../../pages/ChangeLog";
//import Home from "../../pages/Home"
import ProjectsPage from "../projects/ProjectsPage";
import ProjectDetailsPage from "../projects/ProjectDetailsPage";

import NewTimeActivityPage from "../timeActivities/NewTimeActivityPage";
import TimeActivityDetailsPage from "../timeActivities/TimeActivityDetailsPage";

import NewIncomeActivityPage from "../financeActivities/NewIncomeActivityPage";
import NewExpenseActivityPage from "../financeActivities/NewExpenseActivityPage";
import FinanceActivityDetailsPage from "../financeActivities/FinanceActivityDetailsPage";

import { connect } from "../../data/connect";

interface OwnProps {
  path: string;
}

interface StateProps {
  isAuthenticated: boolean;
  hasSeenTutorial: boolean;
}

interface DispatchProps {}

interface ContainerProps extends OwnProps, StateProps, DispatchProps {}

export const PrivateRoute: React.FC<ContainerProps> = ({
  path,
  isAuthenticated,
  hasSeenTutorial,
}) => {
  let location = useLocation();

  if (!isAuthenticated) {
    let redirectPath = path;
    if (location.pathname !== "/") {
      redirectPath = location.pathname;
    }

    if (!localStorage.getItem("redirect")) {
      localStorage.setItem("redirect", redirectPath); //window.location.href);
    }

    return (
      /*<Route path='/' component={Home} />*/
      <Redirect
        to={{
          pathname: "/",
          state: { referrer: redirectPath },
        }}
      />
    );
    //return <Redirect to="/" />;
  } /*else if (path === "/tutorial") {
    return <Route path="/tutorial" component={Tutorial} exact />;
  }*/ else if (path === "/dashboard") {
    return <Route path="/dashboard" component={Dashboard} exact />;
  } else if (path.startsWith("/subscribe")) {
    return <Route path={path} component={Subscribe} />;
  } else if (path.startsWith("/settings")) {
    return <Route path={path} component={Settings} exact />;
  } else if (path.startsWith("/changelog")) {
    return <Route path={path} component={ChangeLog} exact />;
  } else if (path === "/projects") {
    return <Route path="/projects" component={ProjectsPage} exact />;
  } else if (path.startsWith("/projectdetails")) {
    return <Route path={path} component={ProjectDetailsPage} exact />;
  } else if (path.startsWith("/newtimeactivity")) {
    return <Route path={path} component={NewTimeActivityPage} exact />;
  } else if (path.startsWith("/tracktimeactivity")) {
    return <Route path={path} component={NewTimeActivityPage} exact />;
  } else if (path.startsWith("/timeactivity")) {
    return <Route path={path} component={TimeActivityDetailsPage} exact />;
  } else if (path.startsWith("/newincomeactivity")) {
    return <Route path={path} component={NewIncomeActivityPage} exact />;
  } else if (path.startsWith("/trackincomeactivity")) {
    return <Route path={path} component={NewIncomeActivityPage} exact />;
  } else if (path.startsWith("/newexpenseactivity")) {
    return <Route path={path} component={NewExpenseActivityPage} exact />;
  } else if (path.startsWith("/trackexpenseactivity")) {
    return <Route path={path} component={NewExpenseActivityPage} exact />;
  } else if (path.startsWith("/financeactivity")) {
    return <Route path={path} component={FinanceActivityDetailsPage} exact />;
  } else {
    //console.log("Private route isAuthenitcated is true");
    return <Redirect to="/" />;
  }
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    isAuthenticated: state.user.isAuthenticated,
    hasSeenTutorial: state.user.hasSeenTutorial,
  }),
  mapDispatchToProps: {},
  component: PrivateRoute,
});
