import React, { useState, useEffect } from "react";
import {
  IonDatetime,
  IonItem,
  IonLabel,
  IonPopover,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
//import StatsBoard from "./StatsBoard";
import { connect } from "../../data/connect";
import {
  getProjectTimeTrackedChartData,
  setProjectTimeTrackedChartLoading,
  getProjectTotalTimeTrackedChartData,
  setProjectTotalTimeTrackedChartLoading,
  getProjectProfitLossChartData,
  setProjectProfitLossChartLoading,
  getProjectProfitLossPerHourChartData,
  setProjectProfitLossPerHourChartLoading,
  getProjectTimePerProjectChartData,
  setProjectTimePerProjectChartLoading,
} from "../../data/projects/projects.actions";
import ProjectTimeTrackedChart from "./ProjectTimeTrackedChart";
import ProjectTotalTimeTrackedChart from "./ProjectTotalTimeTrackedChart";
import ProjectProfitLossChart from "./ProjectProfitLossChart";
import ProjectProfitLossPerHourChart from "./ProjectProfitLossPerHourChart";
import ProjectTimePerProjectChart from "./ProjectTimePerProjectChart";
import { ChartDateProjectParameters } from "../../models/ChartObjects";
import moment from "moment";

interface OwnProps {}

interface StateProps {}

interface DispatchProps {
  getProjectTimeTrackedChartData: typeof getProjectTimeTrackedChartData;
  setProjectTimeTrackedChartLoading: typeof setProjectTimeTrackedChartLoading;
  getProjectTotalTimeTrackedChartData: typeof getProjectTotalTimeTrackedChartData;
  setProjectTotalTimeTrackedChartLoading: typeof setProjectTotalTimeTrackedChartLoading;
  getProjectProfitLossChartData: typeof getProjectProfitLossChartData;
  setProjectProfitLossChartLoading: typeof setProjectProfitLossChartLoading;
  getProjectProfitLossPerHourChartData: typeof getProjectProfitLossPerHourChartData;
  setProjectProfitLossPerHourChartLoading: typeof setProjectProfitLossPerHourChartLoading;
  getProjectTimePerProjectChartData: typeof getProjectTimePerProjectChartData;
  setProjectTimePerProjectChartLoading: typeof setProjectTimePerProjectChartLoading;
}

interface ChartsDashboardProps extends OwnProps, StateProps, DispatchProps {}

const ChartsDashboard: React.FC<ChartsDashboardProps> = ({
  getProjectTimeTrackedChartData,
  setProjectTimeTrackedChartLoading,
  getProjectTotalTimeTrackedChartData,
  setProjectTotalTimeTrackedChartLoading,
  getProjectProfitLossChartData,
  setProjectProfitLossChartLoading,
  getProjectProfitLossPerHourChartData,
  setProjectProfitLossPerHourChartLoading,
  getProjectTimePerProjectChartData,
  setProjectTimePerProjectChartLoading,
}) => {
  const formatDate = (date: Date) => {
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    return (
      year.toString() +
      "-" +
      (month.toString().length === 2
        ? month.toString()
        : "0" + month.toString()) +
      "-" +
      (day.toString().length === 2 ? day.toString() : "0" + day.toString())
    );
  };

  let systemDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

  var today = new Date();
  var fromDate = new Date();
  fromDate.setMonth(fromDate.getMonth() - 12);

  const [selectionStart, setSelectionStart] = useState<string>(
    formatDate(fromDate)
  );
  const [selectionEnd, setSelectionEnd] = useState<string>(formatDate(today));

  useEffect(() => {
    //let chartDates = { startDate: selectionStart, endDate: selectionEnd };

    let chartDates = {
      startDate: selectionStart,
      endDate: selectionEnd,
      projectId: "",
    } as ChartDateProjectParameters;

    setProjectTimeTrackedChartLoading(true);
    getProjectTimeTrackedChartData(chartDates);

    setProjectTotalTimeTrackedChartLoading(true);
    getProjectTotalTimeTrackedChartData(chartDates);

    setProjectProfitLossChartLoading(true);
    getProjectProfitLossChartData(chartDates);

    setProjectProfitLossPerHourChartLoading(true);
    getProjectProfitLossPerHourChartData(chartDates);

    setProjectTimePerProjectChartLoading(true);
    getProjectTimePerProjectChartData();

    //setProjectTimeTrackedChartLoading(false);
  }, [selectionStart, selectionEnd]);

  const onChangeStart = (fieldValue: string) => {
    //console.log(fieldValue);
    setSelectionStart(fieldValue.substring(0, 10));

    //console.log("onChangeStart triggered")
    //let chartDates = {startDate: fieldValue.substring(0, 10), endDate: selectionEnd}
    //getItemCategoryChartData(chartDates)
  };

  const onChangeEnd = (fieldValue: string) => {
    setSelectionEnd(fieldValue.substring(0, 10));

    //console.log("onChangeEnd triggered")
    //let chartDates = {startDate: selectionStart, endDate: fieldValue.substring(0, 10)}
    //getItemCategoryChartData(chartDates)
  };

  return (
    <div>
      <IonItem
        id="from-date-input"
        className={systemDark ? "" : "ion-item-background"}
      >
        <IonLabel>From date:</IonLabel>

        <IonPopover
          trigger="from-date-input"
          showBackdrop={false}

          //dismissOnSelect={true}
        >
          <IonDatetime
            presentation="date"
            onIonChange={(e) => onChangeStart(e.detail.value!)}
            showDefaultButtons={true}
          />
        </IonPopover>
        <IonInput
          value={selectionStart}
          onIonChange={(e) => onChangeStart(e.detail.value!)}
        ></IonInput>
      </IonItem>
      <IonItem
        id="to-date-input"
        className={systemDark ? "" : "ion-item-background"}
      >
        <IonLabel>To date:</IonLabel>

        <IonPopover
          trigger="to-date-input"
          showBackdrop={false}
          //dismissOnSelect={true}
        >
          <IonDatetime
            presentation="date"
            onIonChange={(e) => onChangeEnd(e.detail.value!)}
            showDefaultButtons={true}
          />
        </IonPopover>
        <IonInput
          value={selectionEnd}
          onIonChange={(e) => onChangeEnd(e.detail.value!)}
        ></IonInput>
      </IonItem>

      <IonGrid>
        <IonRow>
          <IonCol>
            <ProjectTimeTrackedChart
              projectId=""
              selectionStart={selectionStart}
              selectionEnd={selectionEnd}
            />
          </IonCol>
          <IonCol>
            <ProjectTotalTimeTrackedChart projectId="" />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <ProjectProfitLossChart
              projectId=""
              selectionStart={selectionStart}
              selectionEnd={selectionEnd}
            />
          </IonCol>
          <IonCol>
            <ProjectProfitLossPerHourChart projectId="" />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <ProjectTimePerProjectChart />
          </IonCol>
          <IonCol></IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {
    getProjectTimeTrackedChartData,
    setProjectTimeTrackedChartLoading,
    getProjectTotalTimeTrackedChartData,
    setProjectTotalTimeTrackedChartLoading,
    getProjectProfitLossChartData,
    setProjectProfitLossChartLoading,
    getProjectProfitLossPerHourChartData,
    setProjectProfitLossPerHourChartLoading,
    getProjectTimePerProjectChartData,
    setProjectTimePerProjectChartLoading,
  },
  component: React.memo(ChartsDashboard),
});
