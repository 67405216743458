import React, { useState } from "react";
import { IonFab, IonFabButton, IonIcon } from "@ionic/react";
import { add } from "ionicons/icons";
import CreateProjectModal from "../projects/CreateProjectModal";
//import CreateShoppingListModal from "../shoppingLists/"
import { useHistory } from "react-router-dom";

interface OwnProps {
  source: string;
}

interface SingleAddBtnProps extends OwnProps {}

export const SingleAddBtn: React.FC<SingleAddBtnProps> = ({ source }) => {
  const [showProjectModal, setShowProjectModal] = useState(false);

  let history = useHistory();

  const showCreateProjectModal = () => {
    setShowProjectModal(!showProjectModal);
  };

  const onClicked = () => {
    switch (source) {
      case "Projects":
        showCreateProjectModal();
        break;
      case "ShoppingLists":
        history.push("/shoppinglist/new");
        break;
      default:
        break;
    }
  };

  return (
    <IonFab vertical="bottom" horizontal="end" slot="fixed">
      <CreateProjectModal show={showProjectModal} onCreated={() => {}} />
      <IonFabButton color="primary" onClick={onClicked}>
        <IonIcon icon={add} />
      </IonFabButton>
    </IonFab>
  );
};
