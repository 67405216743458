import React, { useState } from "react";
import { IonSelect, IonSelectOption } from "@ionic/react";
import { connect } from "../../data/connect";
import { Project } from "../../models/Project";
import CreateProjectModal from "../projects/CreateProjectModal";

interface OwnProps {
  project: Project;
  onChanged: (project: Project) => void;
  slot: string;
}

interface StateProps {
  projects: Project[];
}

interface DispatchProps {}

//interface CreateItemProps extends OwnProps, StateProps, DispatchProps {}
type SelectProjectProps = OwnProps & StateProps & DispatchProps;

export const SelectProjectModal: React.FC<SelectProjectProps> = ({
  project,
  onChanged,
  slot,
  projects,
}) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [addNewProjectOption, setAddNewProjectOption] = useState<Project>({
    _id: "addNewProjectOption",
    userId: "",
    name: "addNewProjectOption",
    description: "",
    enableEmbedding: false,
    totalHours: 0,
    totalIncome: 0,
    totalExpenses: 0,
  });

  const compareWith = (o1: Project, o2: Project) => {
    return o1 && o2 ? o1._id === o2._id : o1 === o2;
  };

  const onChange = (fieldValue: Project) => {
    if (fieldValue === addNewProjectOption) {
      showCreateProjectModal(true);
    } else {
      onChanged(fieldValue);
    }
  };

  const onCreate = (newProject: Project) => {
    //console.log(newProject)
    //console.log(projects)
    const filteredProjects = projects.filter(
      (project) => project.name === newProject.name
    );
    //console.log(filteredProjects[0])
    if (newProject !== undefined) {
      onChanged(newProject);
    }
  };

  const showCreateProjectModal = (showProjectModal: boolean) => {
    setShowCreateModal(showProjectModal);
  };

  return (
    <>
      <IonSelect
        id="newItem-select-project"
        placeholder="Project"
        compareWith={compareWith}
        value={project}
        onIonChange={(e) => onChange(e.detail.value)}
        slot={slot}
        interface="popover"
      >
        {projects
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((project) => (
            <IonSelectOption key={project._id} value={project}>
              {project.name}
            </IonSelectOption>
          ))}
        <IonSelectOption key="newProject" value={addNewProjectOption}>
          ...Add new project
        </IonSelectOption>
        <CreateProjectModal
          show={showCreateModal}
          onCreated={onCreate}
        ></CreateProjectModal>
      </IonSelect>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    projects: state.projectData.projects,
  }),
  mapDispatchToProps: {},
  component: SelectProjectModal,
});
