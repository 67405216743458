import { combineReducers } from "./combineReducers";
import { userReducer } from "./user/user.reducer";
import { projectsReducer } from "./projects/projects.reducer";
import { timeActivitiesReducer } from "./timeActivities/timeActivities.reducer";
import { financeActivitiesReducer } from "./financeActivities/financeActivities.reducer";

export const initialState: AppState = {
  user: {
    darkMode: true,
    hasSeenTutorial: false,
    isAuthenticated: false,
    isLoggingOut: false,
    loading: false,
    voucherCodeResult: false,
    token: "",
    user: {
      _id: "",
      name: "",
      email: "",
      hasSeenTutorial: false,
      currency: "",
      numberFormat: "",
      premium: false,
      enableEmbedding: false,
      showPoweredBy: true,
    },
    error: "",
  },
  projectData: {
    currentProject: {
      _id: "",
      userId: "",
      name: "",
      description: "",
      enableEmbedding: false,
      totalHours: 0,
      totalIncome: 0,
      totalExpenses: 0,
    },
    projects: [],
    filteredItems: [],
    loading: false,
    projectTimeTrackedChartData: { labels: [], datasets: [] },
    projectTimeTrackedChartLoading: false,
    projectTotalTimeTrackedChartData: { labels: [], datasets: [] },
    projectTotalTimeTrackedChartLoading: false,
    projectProfitLossChartData: { labels: [], datasets: [] },
    projectProfitLossChartLoading: false,
    projectProfitLossPerHourChartData: { labels: [], datasets: [] },
    projectProfitLossPerHourChartLoading: false,
    projectTimePerTaskChartData: { labels: [], datasets: [] },
    projectTimePerTaskChartLoading: false,
    projectTimePerProjectChartData: { labels: [], datasets: [] },
    projectTimePerProjectChartLoading: false,
    projectTimePerTaskGridData: [],
    projectTimePerTaskGridLoading: false,
    projectTimePerSubTaskGridData: [],
    projectTimePerSubTaskGridLoading: false,
    projectTaskList: [],
    projectFinanceTaskList: [],
  },
  timeActivityData: {
    currentTimeActivity: {
      _id: "",
      user: "",
      projectId: "",
      activity: "",
      description: "",
      date: "",
      startTime: "",
      endTime: "",
      duration: 0,
    },
    timeActivities: [],
    loading: false,
  },
  financeActivityData: {
    currentFinanceActivity: {
      _id: "",
      user: "",
      projectId: "",
      activity: "",
      description: "",
      date: "",
      amount: 0,
      expense: false,
      notes: "",
    },
    financeActivities: [],
    loading: false,
  },
};

export const reducers = combineReducers({
  user: userReducer,
  projectData: projectsReducer,
  timeActivityData: timeActivitiesReducer,
  financeActivityData: financeActivitiesReducer,
});

export type AppState = ReturnType<typeof reducers>;
