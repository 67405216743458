import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonLabel,
  IonItem,
  IonTitle,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonButton,
  IonAlert,
  IonPage,
  IonHeader,
  IonToolbar,
  IonCheckbox,
  IonLoading,
} from "@ionic/react";
import { User } from "../models/User";
import {
  editUserSettings,
  deleteUser,
  exportUserData,
} from "../data/user/user.actions";
import { connect } from "../data/connect";
import { Footer } from "../components/layout/Footer";

interface OwnProps {
  name: string;
}

interface StateProps {
  user: User;
}

interface DispatchProps {
  editUserSettings: typeof editUserSettings;
  deleteUser: typeof deleteUser;
  exportUserData: typeof exportUserData;
}

interface SettingsProps extends OwnProps, DispatchProps, StateProps {}

const Settings: React.FC<SettingsProps> = ({
  name,
  user,
  editUserSettings,
  deleteUser,
  exportUserData,
}) => {
  const main = async () => {
    const content = document.querySelector("ion-content.scrollbarSettings");
    const styles = document.createElement("style");

    styles.textContent = `
              ::-webkit-scrollbar {
                width: 5px;
              }
          
              /* Track */
              ::-webkit-scrollbar-track {
                box-shadow: 0 0 5px grey; 
                border-radius: 10px;
              }
          
              /* Handle */
              ::-webkit-scrollbar-thumb {
                background: lightgrey; 
                border-radius: 10px;
              }
          
              /* Handle on hover */
              ::-webkit-scrollbar-thumb:hover {
                background: #bdc9bd; 
              }
            `;
    if (content !== null && content.shadowRoot !== null) {
      content.shadowRoot.appendChild(styles);
    }
  };

  setTimeout(() => {
    main();
  }, 250);

  const [currency, setCurrency] = useState<string>(user.currency);
  const [numberFormat, setNumberFormat] = useState<string>(user.numberFormat);
  const [enableEmbedding, setEnableEmbedding] = useState<boolean>(
    user.enableEmbedding
  );
  const [showPoweredBy, setShowPoweredBy] = useState<boolean>(
    user.showPoweredBy
  );
  const [testNumber, setTestNumber] = useState<number>(12345678.9);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const onClick = () => {
    editUserSettings(currency, numberFormat, enableEmbedding, showPoweredBy);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="secondary">
          <IonTitle>Settings</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent class="scrollbarSettings">
        <IonItem>
          <IonLabel>Account type:</IonLabel>
          <IonItem>{user.premium ? "Premium" : "Free"}</IonItem>
        </IonItem>
        <IonItem>
          <IonLabel>Currency:</IonLabel>
          <IonSelect
            value={currency}
            onIonChange={(e) => setCurrency(e.detail.value)}
          >
            <IonSelectOption value="AUD">AUD</IonSelectOption>
            <IonSelectOption value="CHF">CHF</IonSelectOption>
            <IonSelectOption value="EUR">EUR</IonSelectOption>
            <IonSelectOption value="GBP">GBP</IonSelectOption>
            <IonSelectOption value="INR">INR</IonSelectOption>
            <IonSelectOption value="USD">USD</IonSelectOption>
            <IonSelectOption value="ZAR">ZAR</IonSelectOption>
          </IonSelect>
        </IonItem>
        <IonItem>
          <IonLabel>Number format:</IonLabel>
          <IonSelect
            value={numberFormat}
            onIonChange={(e) => setNumberFormat(e.detail.value)}
          >
            <IonSelectOption value="en-US">en-US</IonSelectOption>
            <IonSelectOption value="de-DE">de-DE</IonSelectOption>
            <IonSelectOption value="en-ZA">en-ZA</IonSelectOption>
            <IonSelectOption value="en-GB">en-GB</IonSelectOption>
          </IonSelect>
        </IonItem>
        <IonItem>
          <IonLabel>Example:</IonLabel>
          <IonInput
            type="number"
            value={testNumber}
            onIonChange={(e) => setTestNumber(parseInt(e.detail.value!, 10))}
          ></IonInput>
          {new Intl.NumberFormat(numberFormat, {
            style: "currency",
            currency: currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(testNumber)}
        </IonItem>
        <IonItem>
          <IonLabel>Enable embedding:</IonLabel>
          <IonCheckbox
            checked={enableEmbedding}
            onIonChange={(e) => setEnableEmbedding(e.detail.checked)}
          />
        </IonItem>
        <IonItem>
          <IonLabel>Show powered by message on embedded charts:</IonLabel>
          <IonCheckbox
            disabled={!user.premium}
            checked={showPoweredBy}
            onIonChange={(e) => setShowPoweredBy(e.detail.checked)}
          />
        </IonItem>
        <IonButton onClick={onClick}>Save</IonButton>
        <IonButton
          routerLink={"/tutorial"}
          routerDirection="none"
          hidden={true}
        >
          Show tutorial
        </IonButton>
        <br />
        <br />
        <IonItem lines="full" color="secondary">
          <IonLabel>Account management</IonLabel>
        </IonItem>
        <IonButton routerLink={"/changelog"} routerDirection="none">
          Show change log
        </IonButton>
        <IonButton
          routerLink={"/subscribe"}
          routerDirection="none"
          disabled={user.premium}
        >
          UPGRADE
        </IonButton>
        <br />
        <IonButton color="danger" onClick={() => setShowConfirmDelete(true)}>
          Delete account
        </IonButton>
        <IonButton onClick={async () => exportUserData()}>
          Download my data
        </IonButton>
        <IonAlert
          isOpen={showConfirmDelete}
          onDidDismiss={() => setShowConfirmDelete(false)}
          //cssClass='my-custom-class'
          header={"Are you sure?!"}
          message={
            "Deleting your account means that <strong>all your data</strong> is gone. <strong>FOREVER</strong>. No way of getting it back. <strong>Are you sure about this?</strong>"
          }
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
              //cssClass: 'secondary',
              /*handler: () => {
                console.log('Confirm Cancel: blah');
              }*/
            },
            {
              text: "Delete!",
              handler: () => {
                //console.log('Confirm Okay: Delete!!!');
                deleteUser();
              },
            },
          ]}
        />
      </IonContent>
      <Footer hide={false} />
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.user.user,
  }),
  mapDispatchToProps: { editUserSettings, deleteUser, exportUserData },
  component: Settings,
});
