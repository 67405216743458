import React, { useState, useEffect } from "react";
import {
  IonModal,
  IonButton,
  IonContent,
  IonLabel,
  IonItem,
  IonIcon,
} from "@ionic/react";
import { copyOutline } from "ionicons/icons";
import { connect } from "../../data/connect";
import { Project } from "../../models/Project";

interface OwnProps {
  show: boolean;
  onClose: () => void;
  type: string;
  userId: string;
  projectId: String;
  chartType: string;
}

interface StateProps {}

interface DispatchProps {}

//interface CreateItemProps extends OwnProps, StateProps, DispatchProps {}
type EmbedChartProps = OwnProps & StateProps & DispatchProps;

export const EmbedChartModal: React.FC<EmbedChartProps> = ({
  show,
  onClose,
  type,
  userId,
  projectId,
  chartType,
}) => {
  const [showModal, setShowModal] = useState(show);

  let embedText = `<link
  href="https://epic-payne-335385.netlify.app/index.css" rel="stylesheet" /> 
  <br />
  <script src="https://epic-payne-335385.netlify.app/index.js"></script>
  <br />
  <div class="metric_soup_chart" type="${type}" projectid="${projectId}" userid="${userId}" charttype="${chartType}"></div>`;

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const close = () => {
    onClose();
    setShowModal(false);
  };

  const copy = () => {
    navigator.clipboard.writeText(embedText);
  };

  return (
    <IonContent>
      <IonModal isOpen={showModal} id="embed-chart-modal">
        <p>
          You can embed this chart on another site by copying and pasting the
          following HTML code:
        </p>
        <IonItem>
          <pre>
            <code>
              &lt;link
              href=&quot;https://epic-payne-335385.netlify.app/index.css&quot;
              rel=&quot;stylesheet&quot; /&gt; <br />
              &lt;script
              src=&quot;https://epic-payne-335385.netlify.app/index.js&quot;&gt;&lt;/script&gt;
              <br />
              &lt;div class=&quot;metric_soup_chart&quot; type=&quot;{type}
              &quot; projectid=&quot;{projectId}&quot; userid=&quot;{userId}
              &quot; charttype=&quot;{chartType}&quot; &gt;&lt;/div&gt;
            </code>
          </pre>
        </IonItem>
        <IonItem>
          <IonButton fill="clear" onClick={() => copy()} slot="end">
            <IonIcon icon={copyOutline} />
          </IonButton>
        </IonItem>
        <p>
          Please remember to enable embedding on your Settings page or else it
          will not work. <br />
          --------------- <br />
          Please refer to the documentation for examples on how to embed your
          charts on a React site.
        </p>
        <IonItem>
          <IonButton onClick={() => close()} slot="end">
            Close
          </IonButton>
        </IonItem>
      </IonModal>
    </IonContent>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: EmbedChartModal,
});
