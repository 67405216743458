import { TimeActivitiesState } from "./timeActivities.state";
import { TimeActivity } from "../../models/TimeActivity";
import { Project } from "../../models/Project";

import { ActionType } from "../../utils/types";
import axios from "axios";

export const setLoading = (isLoading: boolean) =>
  ({
    type: "set-timeActivities-loading",
    isLoading,
  } as const);

export const getTimeActivities =
  (data: Project) => async (dispatch: React.Dispatch<any>) => {
    try {
      //console.info(data);
      if (data._id !== "") {
        const res = await axios.get(`/api/timeactivities/${data._id}`);
        const result = await res.data; //json();

        //console.log(result);

        dispatch(setData(result));
      }
    } catch {
      console.log("Error in timeActivites.actions -> getTimeActivities");
    }
  };

export const addNewTimeActivity =
  (data: TimeActivity) => async (dispatch: React.Dispatch<any>) => {
    try {
      //console.info(data);

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post("/api/timeactivities", data, config);
      const result = await res.data; //json();

      //console.log(result);

      dispatch(addTimeActivity(result));
    } catch {
      console.log("Error in timeActivities.actions -> addNewTimeActivity");
    }
  };

export const editTimeActivity =
  (data: TimeActivity) => async (dispatch: React.Dispatch<any>) => {
    try {
      //console.info(data);
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.put(
        `/api/timeActivities/${data._id}`,
        data,
        config
      );
      const result = await res.data;
      //console.info(result);

      dispatch(updateTimeActivity(result));
    } catch {
      console.log("Error in timeActivities.actions -> editTimeActivity");
    }
  };

export const removeTimeActivity =
  (data: TimeActivity) => async (dispatch: React.Dispatch<any>) => {
    try {
      //console.info(data);
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.delete(`/api/timeActivities/${data._id}`, config);
      /*const result = */ await res.data;
      //console.info(result);

      dispatch(deleteTimeActivity(data));
    } catch {
      console.log("Error in timeActivities.actions -> removeTimeActivity");
    }
  };

export const setData = (data: TimeActivity[]) =>
  ({
    type: "set-timeActivities-data",
    data,
  } as const);

export const getTimeActivity = (data: TimeActivity[]) =>
  ({
    type: "get-timeActivity",
    data,
  } as const);

export const addTimeActivity = (data: TimeActivity) =>
  ({
    type: "add-timeActivity",
    data,
  } as const);

export const updateTimeActivity = (data: TimeActivity) =>
  ({
    type: "update-timeActivity",
    data,
  } as const);

export const deleteTimeActivity = (data: TimeActivity) =>
  ({
    type: "delete-timeActivity",
    data,
  } as const);

export type TimeActivitiesActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setData>
  | ActionType<typeof getTimeActivity>
  | ActionType<typeof addTimeActivity>
  | ActionType<typeof updateTimeActivity>
  | ActionType<typeof deleteTimeActivity>;
