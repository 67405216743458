import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonItem,
  IonToast,
  IonGrid,
  IonRow,
  IonCol,
  IonDatetime,
  IonLabel,
  IonInput,
  IonCheckbox,
  IonIcon,
  IonCard,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
} from "@ionic/react";
import { createOutline } from "ionicons/icons";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { connect } from "../../data/connect";
import { RouteComponentProps } from "react-router";
import { Project } from "../../models/Project";
import { User } from "../../models/User";
import {
  editProject,
  addNewProject,
} from "../../data/projects/projects.actions";
import { formatDuration } from "../../utils/dateTimeFormat";

import TimeActivityGrid from "../timeActivities/TimeActivityGrid";
import FinanceActivityGrid from "../financeActivities/FinanceActivityGrid";
//import ProjectTimeTrackedChart from "../charts/ProjectTimeTrackedChart";
import ProjectChartsDashboard from "../charts/ProjectChartsDashboard";
/*import TimeActivityList from "../timeActivities/TimeActivityList";
import { TimeActivity } from "../../models/TimeActivity";
import { getTimeActivities } from "../../data/timeActivities/timeActivities.actions";*/

//import DatePickerModal from "../layout/DatePickerModal";
//import moment from "moment";
import "../../theme/styling.scss";
//import { filter } from "ionicons/icons";

interface OwnProps extends RouteComponentProps {
  //project: Project;
}

interface DispatchProps {
  editProject: typeof editProject;
  addNewProject: typeof addNewProject;
  //getTimeActivities: typeof getTimeActivities;
}

interface StateProps {
  //isAuthenticated: boolean;
  //error: string;
  //shoppingItems: ShoppingItem[];
  projects: Project[];
  //lastCreatedId: string;
  //timeActivities: TimeActivity[];
  user: User;
}

interface ProjectDetailsPageProps extends OwnProps, DispatchProps, StateProps {}

const ProjectDetailsPage: React.FC<ProjectDetailsPageProps> = ({
  //isAuthenticated,
  //error,
  //history,
  //project,
  projects,
  //lastCreatedId,
  //timeActivities,
  user,
  editProject,
  addNewProject,
  //getTimeActivities,
}) => {
  var { projectid } = useParams<{ projectid: string }>();
  //console.log(projectid);

  const [showToastCreated, setShowToastCreated] = useState(false);
  const [showDatePickerModal, setShowDatePickerModal] = useState(false);
  const [editName, setEditName] = useState(true);
  const [project, setProject] = useState<Project>({
    _id: "",
    userId: "",
    name: "",
    description: "",
    enableEmbedding: false,
    totalHours: 0,
    totalIncome: 0,
    totalExpenses: 0,
  });
  const [modifiedProject, setModifiedProject] = useState<Project>(project);
  //const [date, setDate] = useState(modifiedProject.date);

  const name = modifiedProject.name;
  const description = modifiedProject.description;

  let systemDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

  const main = async () => {
    const content = document.querySelector("ion-content.scrollbarProjectItems");
    const styles = document.createElement("style");

    styles.textContent = `
            ::-webkit-scrollbar {
              width: 5px;
            }
        
            /* Track */
            ::-webkit-scrollbar-track {
              box-shadow: 0 0 5px grey; 
              border-radius: 10px;
            }
        
            /* Handle */
            ::-webkit-scrollbar-thumb {
              background: lightgrey; 
              border-radius: 10px;
            }
        
            /* Handle on hover */
            ::-webkit-scrollbar-thumb:hover {
              background: #bdc9bd; 
            }
          `;
    if (content !== null && content.shadowRoot !== null) {
      content.shadowRoot.appendChild(styles);
    }
  };

  setTimeout(() => {
    main();
  }, 250);

  useEffect(() => {
    (async function loadData() {
      if (projectid !== "") {
        const filteredProjects = projects.filter(
          (proj) => proj._id === projectid
        );
        if (filteredProjects.length > 0) {
          setProject(filteredProjects[0]);
          setModifiedProject(filteredProjects[0]);
          //await getShoppingItems(filteredProjects[0]);
        }
      }
    })();
    //console.log("useEffect triggered");
    //systemDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  }, [/*getShoppingItems, */ projects, projectid]);

  const editCurrentProject = async () => {
    if (modifiedProject._id === "") {
      addNewProject(modifiedProject);
    } else {
      editProject(modifiedProject);
    }
    setShowToastCreated(true);
  };

  const onChange = (fieldName: string, fieldValue: string) => {
    //console.log(fieldValue)
    setModifiedProject({
      ...modifiedProject,
      [fieldName]: fieldValue,
    });
  };

  const onChangeEnabled = (fieldName: string, fieldValue: boolean) => {
    //console.log(fieldValue)
    setModifiedProject({
      ...modifiedProject,
      [fieldName]: fieldValue,
    });
  };

  const onChangeDatePicker = (dateValue: string) => {
    //console.log(dateValue);
    onChange("date", dateValue);
    setShowDatePickerModal(false);
  };

  const toggleEditName = () => {
    setEditName(!editName);
    console.log(editName);
  };

  return (
    <IonPage id="projectDetails">
      <IonHeader>
        <IonToolbar color="secondary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle class="ion-text-center">
            <IonInput
              value={modifiedProject.name}
              onIonChange={(e) => onChange("name", e.detail.value!)}
              disabled={!editName}
              placeholder="Name"
            ></IonInput>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent class="scrollbarProjectItems">
        <IonItem className={systemDark ? "" : "ion-item-background"}>
          <IonInput
            value={modifiedProject.description}
            onIonChange={(e) => onChange("description", e.detail.value!)}
            class="ion-text-center"
            placeholder="Description"
          ></IonInput>
        </IonItem>

        <IonGrid>
          <IonRow>
            <IonCol>
              <IonCard>
                <IonCardSubtitle>Total time tracked</IonCardSubtitle>
                <IonCardTitle>
                  {formatDuration(project.totalHours)}
                </IonCardTitle>
              </IonCard>
            </IonCol>
            <IonCol>
              <IonCard>
                <IonCardSubtitle>Total income</IonCardSubtitle>
                <IonCardTitle>
                  {new Intl.NumberFormat(`${user.numberFormat}`, {
                    style: "currency",
                    currency: `${user.currency}`,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(project.totalIncome)}
                </IonCardTitle>
              </IonCard>
            </IonCol>
            <IonCol>
              <IonCard>
                <IonCardSubtitle>Total expenses</IonCardSubtitle>
                <IonCardTitle>
                  {new Intl.NumberFormat(`${user.numberFormat}`, {
                    style: "currency",
                    currency: `${user.currency}`,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(project.totalExpenses)}
                </IonCardTitle>
              </IonCard>
            </IonCol>
            <IonCol>
              <IonCard>
                <IonCardSubtitle>Profit/Loss</IonCardSubtitle>
                <IonCardTitle
                  color={
                    project.totalIncome - project.totalExpenses > 0
                      ? "success"
                      : "danger"
                  }
                >
                  {new Intl.NumberFormat(`${user.numberFormat}`, {
                    style: "currency",
                    currency: `${user.currency}`,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(project.totalIncome - project.totalExpenses)}
                </IonCardTitle>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonItem className={systemDark ? "" : "ion-item-background"}>
          <IonLabel>Enable embedding:</IonLabel>
          <IonCheckbox
            checked={modifiedProject.enableEmbedding}
            onIonChange={(e) =>
              onChangeEnabled("enableEmbedding", e.detail.checked)
            }
          ></IonCheckbox>
        </IonItem>
        <IonItem>
          <IonButton
            //routerLink={`/Projects`}
            //routerDirection="none"
            onClick={editCurrentProject}
            slot="end"
          >
            {modifiedProject._id === "" ? "Create " : "Update "}project
          </IonButton>
          <IonButton routerLink={`/Projects`} routerDirection="none" slot="end">
            Close
          </IonButton>
        </IonItem>

        <TimeActivityGrid project={project} />
        <br />
        <FinanceActivityGrid project={project} />
        <br />
        <ProjectChartsDashboard projectId={project._id} />

        <IonToast
          isOpen={showToastCreated}
          onDidDismiss={() => setShowToastCreated(false)}
          message={`Your project has been ${
            modifiedProject._id === "" ? "created" : "updated"
          }.`}
          duration={1500}
          color="primary"
        />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    //isAuthenticated: state.user.isAuthenticated,
    //error: state.user.error,
    projects: state.projectData.projects,
    user: state.user.user,
    //timeActivities: state.timeActivityData.timeActivities,
  }),
  mapDispatchToProps: {
    editProject,
    addNewProject,
    //getTimeActivities,
  },
  component: ProjectDetailsPage,
});
