import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonItem,
  IonToast,
  IonDatetime,
  IonLabel,
  IonInput,
  IonText,
  IonPopover,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { connect } from "../../data/connect";
import { RouteComponentProps } from "react-router";
import { Project } from "../../models/Project";
import { TimeActivity } from "../../models/TimeActivity";
import { User } from "../../models/User";
import { editTimeActivity } from "../../data/timeActivities/timeActivities.actions";
import SelectProjectModal from "../projects/SelectProjectModal";
import { TimerButton } from "../layout/TimerButton";
import {
  formatDate,
  formatTime,
  formatDuration,
  formatFullDuration,
} from "../../utils/dateTimeFormat";

//import DatePickerModal from "../layout/DatePickerModal";
//import moment from "moment";
import "../../theme/styling.scss";
//import { filter } from "ionicons/icons";

interface OwnProps extends RouteComponentProps {
  //project: Project;
}

interface DispatchProps {
  editTimeActivity: typeof editTimeActivity;
}

interface StateProps {
  timeActivities: TimeActivity[];
  projects: Project[];
  user: User;
}

interface TimeActivityDetailsPageProps
  extends OwnProps,
    DispatchProps,
    StateProps {}

const TimeActivityDetailsPage: React.FC<TimeActivityDetailsPageProps> = ({
  timeActivities,
  projects,
  user,
  editTimeActivity,
}) => {
  var { timeActivityId } = useParams<{ timeActivityId: string }>();

  const [showToastCreated, setShowToastCreated] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [timeActivity, setTimeActivity] = useState<TimeActivity>({
    _id: "",
    user: "",
    projectId: "",
    activity: "",
    description: "",
    date: "",
    startTime: "",
    endTime: "",
    duration: 0,
  });
  const [project, setProject] = useState<Project>({
    _id: "",
    userId: "",
    name: "",
    description: "",
    enableEmbedding: false,
    totalHours: 0,
    totalIncome: 0,
    totalExpenses: 0,
  });

  const projectId = timeActivity.projectId;
  const activity = timeActivity.activity;
  const description = timeActivity.description;

  const date = timeActivity.date.endsWith("Z")
    ? formatDate(timeActivity.date)
    : timeActivity.date;
  const startTime = timeActivity.startTime;
  const endTime = timeActivity.endTime;
  const duration = timeActivity.duration;

  //setDate(timeActivity.date.toDateString());

  let systemDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

  const main = async () => {
    const content = document.querySelector("ion-content.scrollbarProjectItems");
    const styles = document.createElement("style");

    styles.textContent = `
                ::-webkit-scrollbar {
                  width: 5px;
                }
            
                /* Track */
                ::-webkit-scrollbar-track {
                  box-shadow: 0 0 5px grey; 
                  border-radius: 10px;
                }
            
                /* Handle */
                ::-webkit-scrollbar-thumb {
                  background: lightgrey; 
                  border-radius: 10px;
                }
            
                /* Handle on hover */
                ::-webkit-scrollbar-thumb:hover {
                  background: #bdc9bd; 
                }
              `;
    if (content !== null && content.shadowRoot !== null) {
      content.shadowRoot.appendChild(styles);
    }
  };

  setTimeout(() => {
    main();
  }, 250);

  useEffect(() => {
    (async function loadData() {
      if (timeActivityId !== "") {
        const filteredActivities = timeActivities.filter(
          (activity) => activity._id === timeActivityId
        );
        if (filteredActivities.length > 0) {
          setTimeActivity(filteredActivities[0]);

          const filteredProjects = projects.filter(
            (project) => project._id === filteredActivities[0].projectId
          );

          if (filteredProjects.length > 0) {
            setProject(filteredProjects[0]);
          }
        }
      }
    })();

    setDuration();
  }, [project, timeActivityId, startTime, endTime, timeActivities]);

  const getNullTime = () => {
    let today = new Date();
    if (date) {
      today = new Date(date);
    }
    today.setUTCHours(0);
    today.setUTCMinutes(0);
    today.setUTCSeconds(0);
    today.setUTCMilliseconds(0);

    return today;
  };

  const copyTime = (dateToCopy: Date) => {
    let newTime = getNullTime();
    newTime.setHours(dateToCopy.getHours());
    newTime.setMinutes(dateToCopy.getMinutes());
    newTime.setSeconds(dateToCopy.getSeconds());

    return newTime;
  };

  const checkTimeActivity = () => {
    let startTimeCheck = new Date(startTime);
    let endTimeCheck = new Date(endTime);
    let endTimeNorm = copyTime(endTimeCheck);
    let startTimeNorm = copyTime(startTimeCheck);

    if (startTimeNorm > endTimeNorm && endTime !== "") {
      console.log("Error: Start time after end time");
      setShowToastError(true);
      return false;
    }

    if (projectId) return true;
  };

  const setDuration = () => {
    if (startTime !== "" && endTime !== "") {
      let startTimeCheck = new Date(startTime);
      let endTimeCheck = new Date(endTime);

      let endTimeNorm = copyTime(endTimeCheck);
      let startTimeNorm = copyTime(startTimeCheck);

      let durationCalc = endTimeNorm.getTime() - startTimeNorm.getTime();

      setTimeActivity({
        ...timeActivity,
        duration: durationCalc,
      });
    }
  };

  const saveTimeActivity = async () => {
    if (checkTimeActivity()) {
      editTimeActivity(timeActivity);
      setShowToastCreated(true);
    }
  };

  const onChange = (fieldName: string, fieldValue: string) => {
    setTimeActivity({
      ...timeActivity,
      [fieldName]: fieldValue,
    });
  };

  const onChangeTime = (fieldName: string, fieldValue: string) => {
    setTimeActivity({
      ...timeActivity,
      [fieldName]: fieldValue,
    });

    setDuration();
  };

  const onChangeDuration = (fieldValue: string) => {
    console.log(fieldValue);
    let today = getNullTime();

    let todayTime = today.getTime();
    let durationTime = new Date(fieldValue).getTime();
    let durationCalc =
      durationTime -
      todayTime -
      new Date(fieldValue).getTimezoneOffset() * 60000;
    console.log(durationCalc);

    setTimeActivity({
      ...timeActivity,
      duration: durationCalc,
    });
  };

  const onChangeProject = (fieldValue: Project) => {
    //console.log(fieldValue)
    if (fieldValue !== undefined) {
      if (fieldValue._id === "") {
        const filteredProjects = projects.filter(
          (project) => project.name === fieldValue.name
        );
        //console.log(filteredProjects[0])
        //onChanged(filteredProjects[0]);
        if (filteredProjects.length > 0) {
          setTimeActivity({
            ...timeActivity,
            projectId: filteredProjects[0]._id,
          });
          setProject(filteredProjects[0]);
        } else {
          setTimeActivity({
            ...timeActivity,
            projectId: fieldValue._id,
          });
          setProject(fieldValue);
        }
      } else {
        setTimeActivity({
          ...timeActivity,
          projectId: fieldValue._id,
        });
        setProject(fieldValue);
      }
    }
  };

  const onChangeDatePicker = (dateValue: string) => {
    //setDate(dateValue);
    setTimeActivity({
      ...timeActivity,
      date: dateValue,
    });
    //setShowDatePickerModal(false);
  };

  return (
    <IonPage id="projectDetails">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Track time for a project</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent class="scrollbarProjectItems">
        <IonItem className={systemDark ? "" : "ion-item-background"}>
          <IonLabel>Select project:</IonLabel>
          <SelectProjectModal
            project={project}
            onChanged={onChangeProject}
            slot="end"
          />
        </IonItem>
        <IonItem className={systemDark ? "" : "ion-item-background"}>
          <IonLabel>Select task:</IonLabel>
          <IonInput
            value={timeActivity.activity}
            onIonChange={(e) => onChange("activity", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem className={systemDark ? "" : "ion-item-background"}>
          <IonLabel>Description:</IonLabel>
          <IonInput
            value={timeActivity.description}
            onIonChange={(e) => onChange("description", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem
          id="open-date-input"
          className={systemDark ? "" : "ion-item-background"}
        >
          <IonLabel>Select date:</IonLabel>

          <IonPopover trigger="open-date-input" showBackdrop={false}>
            <IonDatetime
              presentation="date"
              showDefaultButtons={true}
              onIonChange={(ev) =>
                onChangeDatePicker(formatDate(ev.detail.value!))
              }
            />
          </IonPopover>
          <IonInput
            value={date}
            onIonChange={(e) => onChange("date", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem
          id="open-start-time-input"
          className={systemDark ? "" : "ion-item-background"}
        >
          <IonLabel>Start time:</IonLabel>
          <IonPopover
            trigger="open-start-time-input"
            showBackdrop={false}
            //dismissOnSelect={true}
          >
            <IonDatetime
              presentation="time"
              onIonChange={(ev) => onChangeTime("startTime", ev.detail.value!)}
              showDefaultButtons={true}
            />
          </IonPopover>
          <IonInput
            value={formatTime(startTime)}
            //onIonChange={(e) => onChange("startTime", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem
          id="open-end-time-input"
          className={systemDark ? "" : "ion-item-background"}
        >
          <IonLabel>End time:</IonLabel>
          <IonPopover
            trigger="open-end-time-input"
            showBackdrop={false}
            //dismissOnSelect={true}
          >
            <IonDatetime
              presentation="time"
              onIonChange={(ev) => onChangeTime("endTime", ev.detail.value!)}
              showDefaultButtons={true}
            />
          </IonPopover>
          <IonInput
            value={formatTime(endTime)}
            //onIonChange={(e) => onChange("endTime", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem
          id="open-duration-input"
          className={systemDark ? "" : "ion-item-background"}
        >
          <IonLabel>Total time:</IonLabel>
          <IonPopover
            trigger="open-duration-input"
            showBackdrop={false}
            //dismissOnSelect={true}
          >
            <IonDatetime
              presentation="time"
              value={getNullTime().toISOString()}
              hour-cycle="h23"
              onIonChange={(ev) => onChangeDuration(ev.detail.value!)}
              showDefaultButtons={true}
            />
          </IonPopover>
          <IonInput
            value={formatDuration(duration)}
            //onIonChange={(e) => onChange("duration", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonButton
            //routerLink={`/Projects`}
            //routerDirection="none"
            onClick={saveTimeActivity}
            slot="end"
          >
            Save
          </IonButton>
          <IonButton
            routerLink={`/projectdetails/${project._id}`}
            routerDirection="none"
            slot="end"
          >
            Close
          </IonButton>
        </IonItem>
        <IonToast
          isOpen={showToastCreated}
          onDidDismiss={() => setShowToastCreated(false)}
          message={`Your activity has been updated!`}
          duration={1500}
          color="primary"
        />
        <IonToast
          isOpen={showToastError}
          onDidDismiss={() => setShowToastError(false)}
          message={`Woah time traveller! Your start time can't be after your end time!`}
          duration={1500}
          color="danger"
        />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    timeActivities: state.timeActivityData.timeActivities,
    projects: state.projectData.projects,
    user: state.user.user,
  }),
  mapDispatchToProps: {
    editTimeActivity,
  },
  component: TimeActivityDetailsPage,
});
