//import { Plugins } from "@capacitor/core";
import { Storage } from "@ionic/storage";
import axios from "axios";

//const { Storage } = Plugins;
const store = new Storage();
store.create();

const HAS_LOGGED_IN = "hasLoggedIn";
const HAS_SEEN_TUTORIAL = "hasSeenTutorial";
const USERNAME = "username";

export const getUserData = async () => {
  const response = await Promise.all([
    store.get(HAS_LOGGED_IN),
    store.get(HAS_SEEN_TUTORIAL),
    store.get(USERNAME),
  ]);
  const isLoggedin = (await response[0].value) === "true";
  const hasSeenTutorial = (await response[1].value) === "true";
  const username = (await response[2].value) || undefined;
  const data = {
    isLoggedin,
    hasSeenTutorial,
    username,
  };

  return data;
};

export const getProjectData = async () => {
  const resProjects = await axios.get("/api/projects");
  const projects = await resProjects.data;

  const data = {
    projects,
  };
  return data;
};

export const setIsLoggedInData = async (isLoggedIn: boolean) => {
  await store.set(HAS_LOGGED_IN, JSON.stringify(isLoggedIn));
};

export const setHasSeenTutorialData = async (hasSeenTutorial: boolean) => {
  /*await Storage.set({
    key: HAS_SEEN_TUTORIAL,
    value: JSON.stringify(hasSeenTutorial),
  });*/
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  await axios.post("/api/userseentutorial", config);
};

export const setUsernameData = async (username?: string) => {
  if (!username) {
    await store.remove(USERNAME);
  } else {
    await store.set(USERNAME, username);
  }
};

export const loadData = async () => {};
