import React from "react";
import { IonRow, IonItemGroup, IonCol } from "@ionic/react";
import { ProjectTimePerSubTaskGridData } from "../../models/Project";
import { connect } from "../../data/connect";
import { formatDuration } from "../../utils/dateTimeFormat";

interface OwnProps {
  subTaskItem: ProjectTimePerSubTaskGridData;
}

interface StateProps {}

interface DispatchProps {}

interface ProjectSubTaskTimeGridItemProps
  extends OwnProps,
    StateProps,
    DispatchProps {}

const ProjectSubTaskTimeGridItem: React.FC<ProjectSubTaskTimeGridItemProps> = ({
  subTaskItem,
}) => {
  return (
    <IonItemGroup>
      <IonRow>
        <IonCol size="1"></IonCol>
        <IonCol size="6">{subTaskItem.description}</IonCol>
        <IonCol size="4">{formatDuration(subTaskItem.totalHours)}</IonCol>
        <IonCol size="1"></IonCol>
      </IonRow>
    </IonItemGroup>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: React.memo(ProjectSubTaskTimeGridItem),
});
