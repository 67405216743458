import React, { useState, useEffect } from "react";
import {
  IonDatetime,
  IonItem,
  IonLabel,
  IonPopover,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonTitle,
  IonCard,
  IonCardSubtitle,
  IonCardHeader,
} from "@ionic/react";
//import StatsBoard from "./StatsBoard";
import { connect } from "../../data/connect";
import {
  getProjectTimeTrackedChartData,
  setProjectTimeTrackedChartLoading,
  getProjectTotalTimeTrackedChartData,
  setProjectTotalTimeTrackedChartLoading,
  getProjectProfitLossChartData,
  setProjectProfitLossChartLoading,
  getProjectProfitLossPerHourChartData,
  setProjectProfitLossPerHourChartLoading,
  getProjectTimePerTaskChartData,
  setProjectTimePerTaskChartLoading,
  getProjectTimePerTaskGridData,
  setProjectTimePerTaskGridLoading,
  getProjectTimePerSubTaskGridData,
  setProjectTimePerSubTaskGridLoading,
} from "../../data/projects/projects.actions";
import ProjectTimeTrackedChart from "./ProjectTimeTrackedChart";
import ProjectTotalTimeTrackedChart from "./ProjectTotalTimeTrackedChart";
import ProjectProfitLossChart from "./ProjectProfitLossChart";
import ProjectProfitLossPerHourChart from "./ProjectProfitLossPerHourChart";
import ProjectTimePerTaskChart from "./ProjectTimePerTaskChart";
import ProjectTaskTimeGrid from "../projectTaskTimeGrid/ProjectTaskTimeGrid";
import { ChartDateProjectParameters } from "../../models/ChartObjects";
//import DateRangePickerModal from "../layout/DateRangePickerModal";
import moment from "moment";

interface OwnProps {
  projectId: String;
}

interface StateProps {}

interface DispatchProps {
  getProjectTimeTrackedChartData: typeof getProjectTimeTrackedChartData;
  setProjectTimeTrackedChartLoading: typeof setProjectTimeTrackedChartLoading;
  getProjectTotalTimeTrackedChartData: typeof getProjectTotalTimeTrackedChartData;
  setProjectTotalTimeTrackedChartLoading: typeof setProjectTotalTimeTrackedChartLoading;
  getProjectProfitLossChartData: typeof getProjectProfitLossChartData;
  setProjectProfitLossChartLoading: typeof setProjectProfitLossChartLoading;
  getProjectProfitLossPerHourChartData: typeof getProjectProfitLossPerHourChartData;
  setProjectProfitLossPerHourChartLoading: typeof setProjectProfitLossPerHourChartLoading;
  getProjectTimePerTaskChartData: typeof getProjectTimePerTaskChartData;
  setProjectTimePerTaskChartLoading: typeof setProjectTimePerTaskChartLoading;
  getProjectTimePerTaskGridData: typeof getProjectTimePerTaskGridData;
  setProjectTimePerTaskGridLoading: typeof setProjectTimePerTaskGridLoading;
  getProjectTimePerSubTaskGridData: typeof getProjectTimePerSubTaskGridData;
  setProjectTimePerSubTaskGridLoading: typeof setProjectTimePerSubTaskGridLoading;
}

interface ProjectChartsDashboardProps
  extends OwnProps,
    StateProps,
    DispatchProps {}

const ProjectChartsDashboard: React.FC<ProjectChartsDashboardProps> = ({
  projectId,
  getProjectTimeTrackedChartData,
  setProjectTimeTrackedChartLoading,
  getProjectTotalTimeTrackedChartData,
  setProjectTotalTimeTrackedChartLoading,
  getProjectProfitLossChartData,
  setProjectProfitLossChartLoading,
  getProjectProfitLossPerHourChartData,
  setProjectProfitLossPerHourChartLoading,
  getProjectTimePerTaskChartData,
  setProjectTimePerTaskChartLoading,
  getProjectTimePerTaskGridData,
  setProjectTimePerTaskGridLoading,
  getProjectTimePerSubTaskGridData,
  setProjectTimePerSubTaskGridLoading,
}) => {
  const formatDate = (date: Date) => {
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    return (
      year.toString() +
      "-" +
      (month.toString().length === 2
        ? month.toString()
        : "0" + month.toString()) +
      "-" +
      (day.toString().length === 2 ? day.toString() : "0" + day.toString())
    );
  };

  let systemDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

  var today = new Date();
  var fromDate = new Date();
  fromDate.setMonth(fromDate.getMonth() - 60);

  const [selectionStart, setSelectionStart] = useState<string>(
    formatDate(fromDate)
  );
  const [selectionEnd, setSelectionEnd] = useState<string>(formatDate(today));

  useEffect(() => {
    //let chartDates = { startDate: selectionStart, endDate: selectionEnd };

    let chartDates = {
      startDate: selectionStart,
      endDate: selectionEnd,
      projectId: projectId,
    } as ChartDateProjectParameters;

    if (projectId !== "") {
      setProjectTimeTrackedChartLoading(true);
      getProjectTimeTrackedChartData(chartDates);

      setProjectTotalTimeTrackedChartLoading(true);
      getProjectTotalTimeTrackedChartData(chartDates);

      setProjectProfitLossChartLoading(true);
      getProjectProfitLossChartData(chartDates);

      setProjectProfitLossPerHourChartLoading(true);
      getProjectProfitLossPerHourChartData(chartDates);

      setProjectTimePerTaskChartLoading(true);
      getProjectTimePerTaskChartData(chartDates);

      setProjectTimePerTaskGridLoading(true);
      getProjectTimePerTaskGridData(chartDates);

      setProjectTimePerSubTaskGridLoading(true);
      getProjectTimePerSubTaskGridData(chartDates);
    }

    //setProjectTimeTrackedChartLoading(false);
  }, [selectionStart, selectionEnd, projectId]);

  const onChangeStart = (fieldValue: string) => {
    //console.log(fieldValue);
    setSelectionStart(fieldValue.substring(0, 10));

    //console.log("onChangeStart triggered")
    //let chartDates = {startDate: fieldValue.substring(0, 10), endDate: selectionEnd}
    //getItemCategoryChartData(chartDates)
  };

  const onChangeEnd = (fieldValue: string) => {
    setSelectionEnd(fieldValue.substring(0, 10));

    //console.log("onChangeEnd triggered")
    //let chartDates = {startDate: selectionStart, endDate: fieldValue.substring(0, 10)}
    //getItemCategoryChartData(chartDates)
  };

  const onChangeDates = (dateValues: string[]) => {
    //console.log(dateValues);
    if (dateValues.length > 1) {
      onChangeStart(dateValues[0]);
      onChangeEnd(dateValues[1]);
    }
  };

  return (
    <div>
      <IonItem color="secondary">
        <IonTitle>Charts and metrics</IonTitle>
      </IonItem>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonCard>
              <IonCardSubtitle>From date</IonCardSubtitle>
              <IonCardHeader>
                <IonItem
                  id="from-date-input"
                  className={systemDark ? "" : "ion-item-background"}
                >
                  <IonPopover
                    trigger="from-date-input"
                    showBackdrop={false}

                    //dismissOnSelect={true}
                  >
                    <IonDatetime
                      presentation="date"
                      onIonChange={(e) => onChangeStart(e.detail.value!)}
                      showDefaultButtons={true}
                    />
                  </IonPopover>
                  <IonInput
                    value={selectionStart}
                    onIonChange={(e) => onChangeStart(e.detail.value!)}
                  ></IonInput>
                </IonItem>
              </IonCardHeader>
            </IonCard>
          </IonCol>
          <IonCol>
            <IonCard>
              <IonCardSubtitle>To date</IonCardSubtitle>
              <IonCardHeader>
                <IonItem
                  id="to-date-input"
                  className={systemDark ? "" : "ion-item-background"}
                >
                  <IonPopover
                    trigger="to-date-input"
                    showBackdrop={false}
                    //dismissOnSelect={true}
                  >
                    <IonDatetime
                      presentation="date"
                      onIonChange={(e) => onChangeEnd(e.detail.value!)}
                      showDefaultButtons={true}
                    />
                  </IonPopover>
                  <IonInput
                    value={selectionEnd}
                    onIonChange={(e) => onChangeEnd(e.detail.value!)}
                  ></IonInput>
                </IonItem>
              </IonCardHeader>
            </IonCard>
          </IonCol>
          <IonCol></IonCol>
          <IonCol></IonCol>
        </IonRow>
      </IonGrid>

      <IonItem
        id="from-date-input"
        className={systemDark ? "" : "ion-item-background"}
      >
        <IonLabel>From date:</IonLabel>

        <IonPopover
          trigger="from-date-input"
          showBackdrop={false}

          //dismissOnSelect={true}
        >
          <IonDatetime
            presentation="date"
            onIonChange={(e) => onChangeStart(e.detail.value!)}
            showDefaultButtons={true}
          />
        </IonPopover>
        <IonInput
          value={selectionStart}
          onIonChange={(e) => onChangeStart(e.detail.value!)}
        ></IonInput>
      </IonItem>
      <IonItem
        id="to-date-input"
        className={systemDark ? "" : "ion-item-background"}
      >
        <IonLabel>To date:</IonLabel>

        <IonPopover
          trigger="to-date-input"
          showBackdrop={false}
          //dismissOnSelect={true}
        >
          <IonDatetime
            presentation="date"
            onIonChange={(e) => onChangeEnd(e.detail.value!)}
            showDefaultButtons={true}
          />
        </IonPopover>
        <IonInput
          value={selectionEnd}
          onIonChange={(e) => onChangeEnd(e.detail.value!)}
        ></IonInput>
      </IonItem>

      <IonGrid>
        <IonRow>
          <IonCol>
            <ProjectTimeTrackedChart
              projectId={projectId}
              selectionStart={selectionStart}
              selectionEnd={selectionEnd}
            />
          </IonCol>
          <IonCol>
            <ProjectTotalTimeTrackedChart projectId={projectId} />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <ProjectProfitLossChart
              projectId={projectId}
              selectionStart={selectionStart}
              selectionEnd={selectionEnd}
            />
          </IonCol>
          <IonCol>
            <ProjectProfitLossPerHourChart projectId={projectId} />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <ProjectTimePerTaskChart
              projectId={projectId}
              selectionStart={selectionStart}
              selectionEnd={selectionEnd}
            />
          </IonCol>
          <IonCol>
            <ProjectTaskTimeGrid
              projectId={projectId}
              selectionStart={selectionStart}
              selectionEnd={selectionEnd}
              hide={false}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {
    getProjectTimeTrackedChartData,
    setProjectTimeTrackedChartLoading,
    getProjectTotalTimeTrackedChartData,
    setProjectTotalTimeTrackedChartLoading,
    getProjectProfitLossChartData,
    setProjectProfitLossChartLoading,
    getProjectProfitLossPerHourChartData,
    setProjectProfitLossPerHourChartLoading,
    getProjectTimePerTaskChartData,
    setProjectTimePerTaskChartLoading,
    getProjectTimePerTaskGridData,
    setProjectTimePerTaskGridLoading,
    getProjectTimePerSubTaskGridData,
    setProjectTimePerSubTaskGridLoading,
  },
  component: React.memo(ProjectChartsDashboard),
});
