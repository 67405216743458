import React, { useState } from "react";
import {
  IonRow,
  IonItemGroup,
  IonAlert,
  IonIcon,
  IonCol,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { trashOutline, pencilOutline } from "ionicons/icons";
import { Project } from "../../models/Project";
import { User } from "../../models/User";
//import EditProjectModal from "./EditProjectModal";
import { removeProject } from "../../data/projects/projects.actions";
import { connect } from "../../data/connect";
import { useHistory } from "react-router-dom";
import { formatDuration } from "../../utils/dateTimeFormat";
import "../../theme/styling.scss";

interface OwnProps {
  project: Project;
}

interface StateProps {
  user: User;
}

interface DispatchProps {
  removeProject: typeof removeProject;
}

interface ProjectItemProps extends OwnProps, StateProps, DispatchProps {}

const ProjectItem: React.FC<ProjectItemProps> = ({
  project,
  user,
  removeProject,
}) => {
  let history = useHistory();

  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const showEditProjectModal = () => {
    setShowEditModal(!showEditModal);
  };

  const showProjectDetailsPage = () => {
    history.push(`/projectdetails/${project._id}`);
  };

  return (
    <IonItemGroup id="listItems">
      <IonRow>
        <IonCol
          size="2"
          sizeSm="2"
          onClick={showProjectDetailsPage}
          class={"ion-item-list"}
        >
          {project.name}
        </IonCol>
        <IonCol
          size="3"
          sizeSm="5"
          onClick={showProjectDetailsPage}
          class={"ion-item-list"}
        >
          {project.description}
        </IonCol>
        <IonCol size="3" sizeSm="1">
          {formatDuration(project.totalHours)}
        </IonCol>
        <IonCol size="1" className="ion-hide-sm-down">
          {new Intl.NumberFormat(`${user.numberFormat}`, {
            style: "currency",
            currency: `${user.currency}`,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(project.totalIncome)}
        </IonCol>
        <IonCol size="1" className="ion-hide-sm-down">
          {new Intl.NumberFormat(`${user.numberFormat}`, {
            style: "currency",
            currency: `${user.currency}`,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(project.totalExpenses)}
        </IonCol>
        <IonCol size="3" sizeSm="1">
          {new Intl.NumberFormat(`${user.numberFormat}`, {
            style: "currency",
            currency: `${user.currency}`,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(project.totalIncome - project.totalExpenses)}
        </IonCol>
        <IonCol>
          <IonIcon
            icon={pencilOutline}
            onClick={() => showProjectDetailsPage()}
          />
          <IonIcon
            icon={trashOutline}
            onClick={() => setShowDeleteAlert(true)}
          />
        </IonCol>
      </IonRow>
      <IonAlert
        isOpen={showDeleteAlert}
        onDidDismiss={() => setShowDeleteAlert(false)}
        header={"Confirm!"}
        message={"<strong>Are you sure you want to delete this?</strong>!!!"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "Okay",
            handler: () => {
              removeProject(project);
            },
          },
        ]}
      />
    </IonItemGroup>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({ user: state.user.user }),
  mapDispatchToProps: { removeProject },
  component: React.memo(ProjectItem),
});
