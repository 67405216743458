import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonItem,
  IonToast,
  IonDatetime,
  IonLabel,
  IonInput,
  IonPopover,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { connect } from "../../data/connect";
import { RouteComponentProps } from "react-router";
import { Project, ProjectTaskList } from "../../models/Project";
import {
  loadProjectData,
  getProjectTaskListData,
} from "../../data/projects/projects.actions";
import { TimeActivity } from "../../models/TimeActivity";
import { User } from "../../models/User";
import { addNewTimeActivity } from "../../data/timeActivities/timeActivities.actions";
import SelectProjectModal from "../projects/SelectProjectModal";
import AutoSuggestString from "../layout/AutoSuggestString";
//import { TimerButton } from "../layout/TimerButton";
import { TimerButtonEnhanced } from "../layout/TimerButtonEnhanced";
import { useHistory } from "react-router-dom";
import {
  formatDate,
  formatTime,
  formatDuration,
} from "../../utils/dateTimeFormat";
import "../../theme/styling.scss";
//import { filter } from "ionicons/icons";

interface OwnProps extends RouteComponentProps {
  //project: Project;
}

interface DispatchProps {
  addNewTimeActivity: typeof addNewTimeActivity;
  loadProjectData: typeof loadProjectData;
  getProjectTaskListData: typeof getProjectTaskListData;
}

interface StateProps {
  projects: Project[];
  user: User;
  projectTaskList: ProjectTaskList[];
}

interface NewTimeActivityPageProps
  extends OwnProps,
    DispatchProps,
    StateProps {}

const NewTimeActivityPage: React.FC<NewTimeActivityPageProps> = ({
  projects,
  user,
  projectTaskList,
  addNewTimeActivity,
  loadProjectData,
  getProjectTaskListData,
}) => {
  var { projectid } = useParams<{ projectid: string }>();

  let history = useHistory();

  const newDate = new Date();
  var timeZoneDifference = (newDate.getTimezoneOffset() / 60) * -1; //convert to positive value.
  newDate.setTime(newDate.getTime() + timeZoneDifference * 60 * 60 * 1000);
  //console.log(newDate.toISOString());

  const [showToastCreated, setShowToastCreated] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  //const [showDatePickerModal, setShowDatePickerModal] = useState(false);
  const [timeActivity, setTimeActivity] = useState<TimeActivity>({
    _id: "",
    user: "",
    projectId: "",
    activity: "",
    description: "",
    date: newDate.toISOString(), //formatDate(newDate.toISOString()),
    startTime: "",
    endTime: "",
    duration: 0,
  });
  const [project, setProject] = useState<Project>({
    _id: "",
    userId: "",
    name: "",
    description: "",
    enableEmbedding: false,
    totalHours: 0,
    totalIncome: 0,
    totalExpenses: 0,
  });
  const [durationDate, setDurationDate] = useState("");
  const [timerRunning, setTimerRunning] = useState(false);
  const [resetTimer, setResetTimer] = useState(false);
  const [filteredTaskList, setFilteredTaskList] = useState<string[]>([]);
  const [filteredSubTaskList, setFilteredSubTaskList] = useState<string[]>([]);

  const projectId = timeActivity.projectId;
  const activity = timeActivity.activity;
  const description = timeActivity.description;

  const date = timeActivity.date;
  const startTime = timeActivity.startTime;
  const endTime = timeActivity.endTime;
  const duration = timeActivity.duration;

  let systemDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

  const main = async () => {
    const content = document.querySelector("ion-content.scrollbarProjectItems");
    const styles = document.createElement("style");

    styles.textContent = `
              ::-webkit-scrollbar {
                width: 5px;
              }
          
              /* Track */
              ::-webkit-scrollbar-track {
                box-shadow: 0 0 5px grey; 
                border-radius: 10px;
              }
          
              /* Handle */
              ::-webkit-scrollbar-thumb {
                background: lightgrey; 
                border-radius: 10px;
              }
          
              /* Handle on hover */
              ::-webkit-scrollbar-thumb:hover {
                background: #bdc9bd; 
              }
            `;
    if (content !== null && content.shadowRoot !== null) {
      content.shadowRoot.appendChild(styles);
    }
  };

  /*if (taskList === undefined) {
    getProjectTaskListData();
  }*/

  setTimeout(() => {
    main();
  }, 250);

  useEffect(() => {
    (async function loadData() {
      if (projectid !== "") {
        const filteredProjects = projects.filter(
          (proj) => proj._id === projectid
        );
        if (filteredProjects.length > 0) {
          setTimeActivity({
            ...timeActivity,
            projectId: projectid,
          });

          setProject(filteredProjects[0]);

          filterTaskList(filteredProjects[0]._id);
          filterSubTaskList(filteredProjects[0]._id, activity);
        }
      }
    })();

    setDuration();
    //console.log("useEffect triggered");
    //systemDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  }, [
    /*getShoppingItems, */ project,
    projectid,
    startTime,
    endTime,
    projectTaskList,
  ]);

  const filterTaskList = (projId: string) => {
    if (projectTaskList !== undefined) {
      const filteredTasks = projectTaskList.filter(
        (task) => task.projectId === projId
      );
      let tasks = [] as string[];
      for (let task of filteredTasks) {
        if (!tasks.includes(task.activity)) {
          tasks.push(task.activity);
        }
      }
      setFilteredTaskList(tasks);
    }
  };

  const filterSubTaskList = (projId: string, activity: string) => {
    if (projectTaskList !== undefined) {
      const filteredTasks = projectTaskList.filter(
        (task) => task.projectId === projId && task.activity === activity
      );
      let tasks = [] as string[];
      for (let task of filteredTasks) {
        if (!tasks.includes(task.description)) {
          tasks.push(task.description);
        }
      }
      setFilteredSubTaskList(tasks);
    }
  };

  const checkTimeActivity = () => {
    let startTimeCheck = new Date(startTime);
    let endTimeCheck = new Date(endTime);

    if (startTimeCheck > endTimeCheck && endTime !== "") {
      console.log("Error: Start time after end time");
      setShowToastError(true);
      return false;
    }

    if (projectId) return true;
  };

  const closeToast = () => {
    setShowToastCreated(false);
    loadProjectData();
    //history.push("/home");
  };

  const setDuration = () => {
    if (startTime !== "" && endTime !== "") {
      let startTimeCheck = new Date(startTime);
      let endTimeCheck = new Date(endTime);
      //console.log(`endTime: ${endTime}`);
      //console.log(`endTimeCheck: ${endTimeCheck}`);

      let durationCalc = endTimeCheck.getTime() - startTimeCheck.getTime();

      setTimeActivity({
        ...timeActivity,
        duration: durationCalc,
      });
    }
  };

  const saveTimeActivity = async () => {
    if (checkTimeActivity()) {
      addNewTimeActivity(timeActivity);
      setShowToastCreated(true);
      setResetTimer(true);

      setTimeActivity({
        _id: "",
        user: "",
        projectId: timeActivity.projectId,
        activity: "",
        description: "",
        date: newDate.toISOString(), //formatDate(newDate.toISOString()),
        startTime: "",
        endTime: "",
        duration: 0,
      });
    }
  };

  const onChange = (fieldName: string, fieldValue: string) => {
    setTimeActivity({
      ...timeActivity,
      [fieldName]: fieldValue,
    });
  };

  const onChangeActivity = (fieldValue: string) => {
    setTimeActivity({
      ...timeActivity,
      ["activity"]: fieldValue,
    });
    filterSubTaskList(projectId, fieldValue);
  };

  const onChangeDescription = (fieldValue: string) => {
    setTimeActivity({
      ...timeActivity,
      ["description"]: fieldValue,
    });
  };

  const onChangeTime = (fieldName: string, fieldValue: string) => {
    setTimeActivity({
      ...timeActivity,
      [fieldName]: fieldValue,
    });
    //console.log("onChangeTime");

    setDuration();
  };

  const getNullTime = () => {
    let today = new Date();
    if (date) {
      today = new Date(date);
    }
    today.setUTCHours(0);
    today.setUTCMinutes(0);
    today.setUTCSeconds(0);
    today.setUTCMilliseconds(0);

    return today;
  };

  const onChangeDuration = (fieldValue: string) => {
    //console.log(fieldValue);
    let today = getNullTime();

    let todayTime = today.getTime();
    let durationTime = new Date(fieldValue).getTime();
    let offset = new Date(fieldValue).getTimezoneOffset() * 60000;
    let durationCalc = durationTime - todayTime - offset;
    //console.log(durationCalc);

    setDurationDate(fieldValue);

    setTimeActivity({
      ...timeActivity,
      duration: durationCalc,
    });
  };

  const setDurationFromTimer = (newDurationValue: number) => {
    setTimeActivity({
      ...timeActivity,
      duration: newDurationValue,
    });
  };

  const onChangeProject = (fieldValue: Project) => {
    //console.log(fieldValue)
    if (fieldValue !== undefined) {
      if (fieldValue._id === "") {
        const filteredProjects = projects.filter(
          (project) => project.name === fieldValue.name
        );
        //console.log(filteredProjects[0])
        //onChanged(filteredProjects[0]);
        if (filteredProjects.length > 0) {
          setTimeActivity({
            ...timeActivity,
            projectId: filteredProjects[0]._id,
          });
          setProject(filteredProjects[0]);
          filterTaskList(filteredProjects[0]._id);
          filterSubTaskList(filteredProjects[0]._id, activity);
        } else {
          setTimeActivity({
            ...timeActivity,
            projectId: fieldValue._id,
          });
          setProject(fieldValue);
          filterTaskList(fieldValue._id);
          filterSubTaskList(fieldValue._id, activity);
        }
      } else {
        setTimeActivity({
          ...timeActivity,
          projectId: fieldValue._id,
        });
        setProject(fieldValue);
        filterTaskList(fieldValue._id);
        filterSubTaskList(fieldValue._id, activity);
      }
    }
  };

  const onChangeDatePicker = (dateValue: string) => {
    //console.log(timeActivity.date);
    //console.log(dateValue);

    let thisDate = new Date(dateValue);
    //console.log(thisDate.toISOString());
    //setDate(dateValue);
    setTimeActivity({
      ...timeActivity,
      date: thisDate.toISOString(), //dateValue,
    });
    //setShowDatePickerModal(false);
  };

  return (
    <IonPage id="projectDetails">
      <IonHeader>
        <IonToolbar color="secondary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Track time for a project</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent class="scrollbarProjectItems">
        <IonItem
          className={systemDark ? "" : "ion-item-background"}
          disabled={timerRunning}
        >
          <IonLabel>Select project:</IonLabel>
          <SelectProjectModal
            project={project}
            onChanged={onChangeProject}
            slot="end"
          />
        </IonItem>
        <AutoSuggestString
          label="Select task:"
          value={activity}
          dataset={filteredTaskList}
          disabled={timerRunning}
          onChange={onChangeActivity}
        />
        <AutoSuggestString
          label="Sub-task:"
          value={description}
          dataset={filteredSubTaskList}
          disabled={timerRunning}
          onChange={onChangeDescription}
        />
        <IonItem className={systemDark ? "" : "ion-item-background"}>
          <TimerButtonEnhanced
            duration={duration}
            reset={resetTimer}
            setDuration={setDurationFromTimer}
            setTimerRunning={setTimerRunning}
            setReset={setResetTimer}
          />
        </IonItem>
        <IonItem
          id="open-date-input"
          className={systemDark ? "" : "ion-item-background"}
          disabled={timerRunning}
        >
          <IonLabel>Select date:</IonLabel>

          <IonPopover
            trigger="open-date-input"
            showBackdrop={false}
            //dismissOnSelect={true}
          >
            <IonDatetime
              presentation="date"
              onIonChange={(ev) => onChangeDatePicker(ev.detail.value!)}
              showDefaultButtons={true}
            />
          </IonPopover>
          <IonInput
            value={formatDate(date)}
            //onIonChange={(e) => onChange("date", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem
          id="open-start-time-input"
          className={systemDark ? "" : "ion-item-background"}
          disabled={timerRunning}
        >
          <IonLabel>Start time:</IonLabel>
          <IonPopover
            trigger="open-start-time-input"
            showBackdrop={false}
            //dismissOnSelect={true}
          >
            <IonDatetime
              presentation="time"
              onIonChange={(ev) => onChangeTime("startTime", ev.detail.value!)}
              showDefaultButtons={true}
            />
          </IonPopover>
          <IonInput
            value={formatTime(startTime)}
            //onIonChange={(e) => onChange("startTime", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem
          id="open-end-time-input"
          className={systemDark ? "" : "ion-item-background"}
          disabled={timerRunning}
        >
          <IonLabel>End time:</IonLabel>
          <IonPopover
            trigger="open-end-time-input"
            showBackdrop={false}
            //dismissOnSelect={true}
          >
            <IonDatetime
              presentation="time"
              onIonChange={(ev) => onChangeTime("endTime", ev.detail.value!)}
              showDefaultButtons={true}
            />
          </IonPopover>
          <IonInput
            value={formatTime(endTime)}
            //onIonChange={(e) => onChange("endTime", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem
          id="open-duration-input"
          className={systemDark ? "" : "ion-item-background"}
          disabled={timerRunning}
        >
          <IonLabel>Total time:</IonLabel>
          <IonPopover
            trigger="open-duration-input"
            showBackdrop={false}
            //dismissOnSelect={true}
          >
            <IonDatetime
              presentation="time"
              value={
                durationDate === "" ? getNullTime().toISOString() : durationDate
              }
              hour-cycle="h23"
              onIonChange={(ev) => onChangeDuration(ev.detail.value!)}
              showDefaultButtons={true}
            />
          </IonPopover>
          <IonInput
            value={formatDuration(duration)}
            //onIonChange={(e) => onChange("duration", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonButton
            //routerLink={`/Projects`}
            //routerDirection="none"
            onClick={saveTimeActivity}
            slot="end"
          >
            Save and clear
          </IonButton>
          <IonButton routerLink={`/Home`} routerDirection="none" slot="end">
            Close
          </IonButton>
        </IonItem>
        <IonToast
          isOpen={showToastCreated}
          onDidDismiss={() => closeToast()}
          message={`Your time has been tracked!`}
          duration={1500}
          color="primary"
        />
        <IonToast
          isOpen={showToastError}
          onDidDismiss={() => setShowToastError(false)}
          message={`Woah time traveller! Your start time can't be after your end time!`}
          duration={1500}
          color="danger"
        />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    //isAuthenticated: state.user.isAuthenticated,
    //error: state.user.error,
    projects: state.projectData.projects,
    user: state.user.user,
    projectTaskList: state.projectData.projectTaskList,
  }),
  mapDispatchToProps: {
    addNewTimeActivity,
    loadProjectData,
    getProjectTaskListData,
  },
  component: NewTimeActivityPage,
});
