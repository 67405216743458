import React, { useState } from "react";
import { IonInput, IonItem, IonLabel } from "@ionic/react";

interface OwnProps {
  label: string;
  value: string;
  dataset: string[];
  disabled: boolean;
  onChange: (value: string) => void;
}

interface AutoSuggestStringProps extends OwnProps {}

const AutoSuggestString: React.FC<AutoSuggestStringProps> = ({
  label,
  value,
  dataset,
  disabled,
  onChange,
}) => {
  const [currentValue, setCurrentValue] = useState(value);
  const [currentSelection, setCurrentSelection] = useState(0);
  const [suggestions, setSuggestions] = useState([] as string[]);

  let systemDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

  const bgColor = systemDark ? "black" : "white";

  const handleChange = (fieldValue: string) => {
    if (currentValue !== fieldValue) {
      setCurrentValue(fieldValue);
      onChange(fieldValue);
      const matchingSuggestions = dataset.filter((suggestion) =>
        suggestion.toLowerCase().startsWith(fieldValue.toLowerCase())
      );
      setSuggestions(matchingSuggestions);
    }
  };

  const handleSelection = (suggestion: string) => {
    setCurrentValue(suggestion);
    onChange(suggestion);
    setSuggestions([]);
  };

  const onKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSelection(suggestions[currentSelection]);
    } else if (e.key === "ArrowDown") {
      if (currentSelection < suggestions.length - 1) {
        setCurrentSelection(currentSelection + 1);
      } else {
        setCurrentSelection(0);
      }
    } else if (e.key === "ArrowUp") {
      if (currentSelection > 0) {
        setCurrentSelection(currentSelection - 1);
      } else {
        setCurrentSelection(suggestions.length - 1);
      }
    }
  };

  const styles = {
    list: {
      position: "absolute",
      zIndex: 100,
      top: "100%",
      width: "100%",
      backgroundColor: "white",
      listStyleType: "none",
      padding: 0,
      margin: 0,
    },
    item: {
      padding: "0.5em",
    },
  };

  return (
    <div>
      <IonItem disabled={disabled}>
        <IonLabel>{label}</IonLabel>
        <IonInput
          value={currentValue}
          onIonChange={(e) => handleChange(e.detail.value!)}
          onKeyDown={(e) => onKeyPress(e)}
        ></IonInput>
      </IonItem>
      <ul
        className="suggestions-list"
        style={{
          position: "absolute",
          zIndex: 100,
          //top: "100%",
          //width: "100%",
          listStyleType: "none",
          backgroundColor: bgColor,
          //borderWidth: 10,
          //borderColor: "green",
          //padding: 0,
          //margin: 0,
        }}
      >
        {suggestions.map((suggestion) => (
          <li
            key={suggestion}
            style={{
              padding: "0.5em",
              zIndex: 100 /*, backgroundColor: {suggestion === currentSelection? "grey" : "default"}*/,
            }}
            onClick={() => handleSelection(suggestion)}
          >
            {suggestion === suggestions[currentSelection] ? (
              <b>
                <u>{suggestion}</u>
              </b>
            ) : (
              suggestion
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AutoSuggestString;
