import React, { useEffect } from "react";

import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Home from "./pages/Home";
import LoggedOut from "./pages/LoggedOut";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Impressum from "./pages/Impressum";
import ConfirmEmail from "./pages/ConfirmEmail";
import Demo from "./pages/Demo";

import Menu from "./components/layout/Menu";
import { connect } from "./data/connect";
import { AppContextProvider } from "./data/AppContext";

import { setIsLoggedIn, loadUserData } from "./data/user/user.actions";
import {
  loadProjectData,
  getProjectTaskListData,
  getProjectFinanceTaskListData,
} from "./data/projects/projects.actions";
import setAuthToken from "./utils/setAuthToken";
import PrivateRoute from "./components/routing/PrivateRoute";

import Plausible from "plausible-tracker";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

setupIonicReact();

const App: React.FC = () => {
  return (
    <AppContextProvider>
      <IonicAppConnected />
    </AppContextProvider>
  );
};

interface StateProps {
  darkMode: boolean;
  isAuthenticated: boolean;
  isLoggingOut: boolean;
  loading: boolean;
}

interface DispatchProps {
  loadUserData: typeof loadUserData;
  setIsLoggedIn: typeof setIsLoggedIn;
  loadProjectData: typeof loadProjectData;
  getProjectTaskListData: typeof getProjectTaskListData;
  getProjectFinanceTaskListData: typeof getProjectFinanceTaskListData;
}

interface IonicAppProps extends StateProps, DispatchProps {}

const IonicApp: React.FC<IonicAppProps> = ({
  darkMode,
  isAuthenticated,
  isLoggingOut,
  loading,
  loadUserData,
  loadProjectData,
  getProjectTaskListData,
  getProjectFinanceTaskListData,
}) => {
  useEffect(() => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    if (!isAuthenticated && !isLoggingOut) {
      (async function loadData() {
        await loadUserData();
      })();
    }

    if (isAuthenticated) {
      // Load data
      loadProjectData();
      getProjectTaskListData();
      getProjectFinanceTaskListData();
    }
    // eslint-disable-next-line
  }, [isAuthenticated, isLoggingOut]);

  const plausible = Plausible({ domain: "app.metricsoup.app" });
  plausible.enableAutoPageviews();

  const main = async () => {
    const content = document.querySelector("ion-content");
    const styles = document.createElement("style");

    styles.textContent = `
      ::-webkit-scrollbar {
        width: 5px;
      }
  
      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: 0 0 5px grey; 
        border-radius: 10px;
      }
  
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: lightgrey; 
        border-radius: 10px;
      }
  
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #bdc9bd; 
      }
    `;
    if (content !== null && content.shadowRoot !== null) {
      content.shadowRoot.appendChild(styles);
    }
  };

  setTimeout(() => {
    main();
  }, 250);

  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  return (
    <IonApp className={`${darkMode ? "dark-theme" : ""}`}>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu hide={!isAuthenticated} />
          <IonRouterOutlet id="main">
            <PrivateRoute path="/dashboard" />
            <PrivateRoute path="/projects" />
            <PrivateRoute path="/subscribe" />
            <PrivateRoute path="/settings" />
            <PrivateRoute path="/changelog" />
            <PrivateRoute path="/projectdetails/:projectid" />
            <PrivateRoute path="/newtimeactivity/:projectid" />
            <PrivateRoute path="/tracktimeactivity" />
            <PrivateRoute path="/timeactivity/:timeActivityId" />
            <PrivateRoute path="/newincomeactivity/:projectid" />
            <PrivateRoute path="/trackincomeactivity" />
            <PrivateRoute path="/newexpenseactivity/:projectid" />
            <PrivateRoute path="/trackexpenseactivity" />
            <PrivateRoute path="/financeactivity/:financeActivityId" />
            <Route path="/login" component={Login} exact />
            <Route path="/signup" component={Signup} exact />
            <Route path="/home" component={Home} exact />
            <Route path="/loggedout" component={LoggedOut} exact />
            <Route path="/forgotpassword" component={ForgotPassword} exact />
            <Route path="/confirmEmail/:token" component={ConfirmEmail} exact />
            <Route
              path="/resetpassword/:token"
              component={ResetPassword}
              exact
            />
            <Route path="/impressum" component={Impressum} exact />
            <Route path="/demo" component={Demo} exact />
            <Route path="/" component={Home} exact />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;

const IonicAppConnected = connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    isAuthenticated: state.user.isAuthenticated,
    isLoggingOut: state.user.isLoggingOut,
    loading: state.user.loading,
  }),
  mapDispatchToProps: {
    loadUserData,
    setIsLoggedIn,
    loadProjectData,
    getProjectTaskListData,
    getProjectFinanceTaskListData,
  },
  component: IonicApp,
});
