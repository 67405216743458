import { UserActions } from "./user.actions";
import { UserState } from "./user.state";

export function userReducer(state: UserState, action: UserActions): UserState {
  switch (action.type) {
    case "register-user-success":
    case "login-success":
      localStorage.setItem("token", action.token);
      return {
        ...state,
        token: action.token,
        //isAuthenticated: true,
        isLoggingOut: false,
        loading: false,
      };
    case "register-user-failure":
    case "login-failure":
      localStorage.removeItem("token");
      return {
        ...state,
        token: "",
        isAuthenticated: false,
        loading: false,
        user: {
          _id: "",
          name: "",
          email: "",
          hasSeenTutorial: false,
          currency: "",
          numberFormat: "",
          premium: false,
          enableEmbedding: false,
          showPoweredBy: true,
        },
        error: action.error,
      };
    case "logout":
      localStorage.removeItem("token");
      return {
        ...state,
        token: "",
        isAuthenticated: false,
        isLoggingOut: true,
        loading: false,
        user: {
          _id: "",
          name: "",
          email: "",
          hasSeenTutorial: false,
          currency: "",
          numberFormat: "",
          premium: false,
          enableEmbedding: false,
          showPoweredBy: true,
        },
        error: "",
      };
    case "set-user-loading":
      return { ...state, loading: action.isLoading };
    case "set-user":
      return {
        ...state,
        user: action.user,
        isAuthenticated: true,
        isLoggingOut: false,
        hasSeenTutorial: action.user.hasSeenTutorial
          ? action.user.hasSeenTutorial
          : false,
      };
    case "clear-error":
      return { ...state, error: "" };
    case "set-user-data":
      return { ...state, ...action.data };
    case "set-has-seen-tutorial":
      return { ...state, hasSeenTutorial: action.hasSeenTutorial };
    case "update-user-settigs":
      return { ...state, user: action.data };
    case "forgot-password":
      return {
        ...state,
        loading: false,
      };
    case "check-reset-token":
    case "check-email-confirm-token":
      return {
        ...state,
        user: action.data,
      };
    case "reset-password":
      return { ...state };
    case "claim-voucher-code":
      return { ...state, voucherCodeResult: action.result };
    case "set-dark-mode":
      return { ...state, darkMode: action.darkMode };
    case "set-is-loggedin":
      return { ...state, isAuthenticated: action.loggedIn };
  }
}
