import { FinanceActivitiesActions } from "./financeActivities.actions";
import { FinanceActivitiesState } from "./financeActivities.state";

export const financeActivitiesReducer = (
  state: FinanceActivitiesState,
  action: FinanceActivitiesActions
): FinanceActivitiesState => {
  switch (action.type) {
    case "set-financeActivities-loading": {
      return { ...state, loading: action.isLoading };
    }
    case "set-financeActivities-data": {
      return { ...state, financeActivities: [...action.data] };
    }
    case "get-financeActivity": {
      return { ...state }; //currentStore: action.data };
    }
    /*case "set-current-financeActivity": {
      return { ...state, currentFinanceActivity: action.data };
    }*/
    case "add-financeActivity": {
      return {
        ...state,
        financeActivities: [action.data, ...state.financeActivities],
      };
    }
    case "update-financeActivity": {
      return {
        ...state,
        financeActivities: state.financeActivities.map((financeActivity) =>
          financeActivity._id === action.data._id
            ? action.data
            : financeActivity
        ),
      };
    }
    case "delete-financeActivity": {
      return {
        ...state,
        financeActivities: state.financeActivities.filter(
          (financeActivity) => financeActivity._id !== action.data._id
        ),
      };
    }
  }
};
