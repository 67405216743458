import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonItem,
  IonToast,
  IonDatetime,
  IonLabel,
  IonInput,
  IonPopover,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { connect } from "../../data/connect";
import { RouteComponentProps } from "react-router";
import { Project } from "../../models/Project";
import { FinanceActivity } from "../../models/FinanceActivity";
import { User } from "../../models/User";
import { editFinanceActivity } from "../../data/financeActivities/financeActivities.actions";
import SelectProjectModal from "../projects/SelectProjectModal";
import { formatDate } from "../../utils/dateTimeFormat";

//import DatePickerModal from "../layout/DatePickerModal";
//import moment from "moment";
import "../../theme/styling.scss";
//import { filter } from "ionicons/icons";

interface OwnProps extends RouteComponentProps {
  //project: Project;
}

interface DispatchProps {
  editFinanceActivity: typeof editFinanceActivity;
}

interface StateProps {
  financeActivities: FinanceActivity[];
  projects: Project[];
  user: User;
}

interface FinanceActivityDetailsPageProps
  extends OwnProps,
    DispatchProps,
    StateProps {}

const FinanceActivityDetailsPage: React.FC<FinanceActivityDetailsPageProps> = ({
  financeActivities,
  projects,
  user,
  editFinanceActivity,
}) => {
  var { financeActivityId } = useParams<{ financeActivityId: string }>();

  const [showToastCreated, setShowToastCreated] = useState(false);
  const [financeActivity, setFinanceActivity] = useState<FinanceActivity>({
    _id: "",
    user: "",
    projectId: "",
    activity: "",
    description: "",
    date: "",
    amount: 0,
    expense: false,
    notes: "",
  });
  const [project, setProject] = useState<Project>({
    _id: "",
    userId: "",
    name: "",
    description: "",
    enableEmbedding: false,
    totalHours: 0,
    totalIncome: 0,
    totalExpenses: 0,
  });

  const projectId = financeActivity.projectId;
  const activity = financeActivity.activity;
  const description = financeActivity.description;

  const date = financeActivity.date.endsWith("Z")
    ? formatDate(financeActivity.date)
    : financeActivity.date;
  const amount = financeActivity.amount;
  const notes = financeActivity.notes;

  //setDate(financeActivity.date.toDateString());

  let systemDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

  const main = async () => {
    const content = document.querySelector("ion-content.scrollbarProjectItems");
    const styles = document.createElement("style");

    styles.textContent = `
                  ::-webkit-scrollbar {
                    width: 5px;
                  }
              
                  /* Track */
                  ::-webkit-scrollbar-track {
                    box-shadow: 0 0 5px grey; 
                    border-radius: 10px;
                  }
              
                  /* Handle */
                  ::-webkit-scrollbar-thumb {
                    background: lightgrey; 
                    border-radius: 10px;
                  }
              
                  /* Handle on hover */
                  ::-webkit-scrollbar-thumb:hover {
                    background: #bdc9bd; 
                  }
                `;
    if (content !== null && content.shadowRoot !== null) {
      content.shadowRoot.appendChild(styles);
    }
  };

  setTimeout(() => {
    main();
  }, 250);

  useEffect(() => {
    (async function loadData() {
      if (financeActivityId !== "") {
        const filteredActivities = financeActivities.filter(
          (activity) => activity._id === financeActivityId
        );
        if (filteredActivities.length > 0) {
          setFinanceActivity(filteredActivities[0]);

          const filteredProjects = projects.filter(
            (project) => project._id === filteredActivities[0].projectId
          );

          if (filteredProjects.length > 0) {
            setProject(filteredProjects[0]);
          }
        }
      }
    })();
  }, [project, financeActivityId, financeActivities]);

  const checkFinanceActivity = () => {
    if (projectId) return true;

    return false;
  };

  const saveFinanceActivity = async () => {
    if (checkFinanceActivity()) {
      editFinanceActivity(financeActivity);
      setShowToastCreated(true);
    }
  };

  const onChange = (fieldName: string, fieldValue: string) => {
    setFinanceActivity({
      ...financeActivity,
      [fieldName]: fieldValue,
    });
  };

  const onChangeProject = (fieldValue: Project) => {
    //console.log(fieldValue)
    if (fieldValue !== undefined) {
      if (fieldValue._id === "") {
        const filteredProjects = projects.filter(
          (project) => project.name === fieldValue.name
        );
        //console.log(filteredProjects[0])
        //onChanged(filteredProjects[0]);
        if (filteredProjects.length > 0) {
          setFinanceActivity({
            ...financeActivity,
            projectId: filteredProjects[0]._id,
          });
          setProject(filteredProjects[0]);
        } else {
          setFinanceActivity({
            ...financeActivity,
            projectId: fieldValue._id,
          });
          setProject(fieldValue);
        }
      } else {
        setFinanceActivity({
          ...financeActivity,
          projectId: fieldValue._id,
        });
        setProject(fieldValue);
      }
    }
  };

  const onChangeDatePicker = (dateValue: string) => {
    //setDate(dateValue);
    setFinanceActivity({
      ...financeActivity,
      date: dateValue,
    });
    //setShowDatePickerModal(false);
  };

  return (
    <IonPage id="projectDetails">
      <IonHeader>
        <IonToolbar color="secondary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Edit a financial activity</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent class="scrollbarProjectItems">
        <IonItem className={systemDark ? "" : "ion-item-background"}>
          <IonLabel>Select project:</IonLabel>
          <SelectProjectModal
            project={project}
            onChanged={onChangeProject}
            slot="end"
          />
        </IonItem>
        <IonItem className={systemDark ? "" : "ion-item-background"}>
          <IonLabel>Select task:</IonLabel>
          <IonInput
            value={financeActivity.activity}
            onIonChange={(e) => onChange("activity", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem
          id="open-date-input"
          className={systemDark ? "" : "ion-item-background"}
        >
          <IonLabel>Select date:</IonLabel>

          <IonPopover trigger="open-date-input" showBackdrop={false}>
            <IonDatetime
              presentation="date"
              showDefaultButtons={true}
              onIonChange={(ev) =>
                onChangeDatePicker(formatDate(ev.detail.value!))
              }
            />
          </IonPopover>
          <IonInput
            value={date}
            onIonChange={(e) => onChange("date", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel>Amount:</IonLabel>
          <IonInput
            type="number"
            inputMode="decimal"
            value={financeActivity.expense ? -amount : amount}
            placeholder="Amount"
            onIonChange={(e) => onChange("amount", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem className={systemDark ? "" : "ion-item-background"}>
          <IonLabel>Description:</IonLabel>
          <IonInput
            value={financeActivity.description}
            onIonChange={(e) => onChange("description", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem className={systemDark ? "" : "ion-item-background"}>
          <IonLabel>Notes:</IonLabel>
          <IonInput
            value={financeActivity.notes}
            onIonChange={(e) => onChange("notes", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonButton
            //routerLink={`/Projects`}
            //routerDirection="none"
            onClick={saveFinanceActivity}
            slot="end"
          >
            Save
          </IonButton>
          <IonButton
            routerLink={`/projectdetails/${project._id}`}
            routerDirection="none"
            slot="end"
          >
            Close
          </IonButton>
        </IonItem>
        <IonToast
          isOpen={showToastCreated}
          onDidDismiss={() => setShowToastCreated(false)}
          message={`Your activity has been updated!`}
          duration={1500}
          color="primary"
        />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    financeActivities: state.financeActivityData.financeActivities,
    projects: state.projectData.projects,
    user: state.user.user,
  }),
  mapDispatchToProps: {
    editFinanceActivity,
  },
  component: FinanceActivityDetailsPage,
});
