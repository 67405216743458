export const allowedToAddProject = (
  totalProjects: number,
  premium: boolean
) => {
  if (!premium && totalProjects >= maxProjects) {
    return false;
  }

  return true;
};

export const allowedToAddItem = (totalItems: number, premium: boolean) => {
  if (!premium && totalItems >= maxItems) {
    return false;
  }

  return true;
};

export const maxProjects = 3;

export const maxItems = 125;
