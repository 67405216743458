import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonItem,
  IonToast,
  IonDatetime,
  IonLabel,
  IonInput,
  IonPopover,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { connect } from "../../data/connect";
import { RouteComponentProps } from "react-router";
import { Project, ProjectTaskList } from "../../models/Project";
import { loadProjectData } from "../../data/projects/projects.actions";
import { FinanceActivity } from "../../models/FinanceActivity";
import { User } from "../../models/User";
import { addNewFinanceActivity } from "../../data/financeActivities/financeActivities.actions";
import SelectProjectModal from "../projects/SelectProjectModal";
import AutoSuggestString from "../layout/AutoSuggestString";
import { formatDate } from "../../utils/dateTimeFormat";
import { useHistory } from "react-router-dom";

//import DatePickerModal from "../layout/DatePickerModal";
//import moment from "moment";
import "../../theme/styling.scss";
//import { filter } from "ionicons/icons";

interface OwnProps extends RouteComponentProps {
  //project: Project;
}

interface DispatchProps {
  addNewFinanceActivity: typeof addNewFinanceActivity;
  loadProjectData: typeof loadProjectData;
}

interface StateProps {
  //isAuthenticated: boolean;
  //error: string;
  //shoppingItems: ShoppingItem[];
  projects: Project[];
  //lastCreatedId: string;
  user: User;
  projectFinanceTaskList: ProjectTaskList[];
}

interface NewIncomeActivityPageProps
  extends OwnProps,
    DispatchProps,
    StateProps {}

const NewIncomeActivityPage: React.FC<NewIncomeActivityPageProps> = ({
  //isAuthenticated,
  //error,
  //history,
  //project,
  projects,
  //lastCreatedId,
  user,
  projectFinanceTaskList,
  addNewFinanceActivity,
  loadProjectData,
}) => {
  var { projectid } = useParams<{ projectid: string }>();

  let history = useHistory();

  const newDate = new Date();
  var timeZoneDifference = (newDate.getTimezoneOffset() / 60) * -1; //convert to positive value.
  newDate.setTime(newDate.getTime() + timeZoneDifference * 60 * 60 * 1000);
  //console.log(projectid);

  /*const formatDate = (value: string) => {
      return format(parseISO(value), "dd MMM yyyy"); //"yyyy-MM-dd");
    };
  
    const formatFinance = (value: string) => {
      return value === "" ? "" : format(parseISO(value), "HH:mm"); //"yyyy-MM-dd");
    };
  
    const formatDuration = (value: number) => {
  
    }*/

  const [showToastCreated, setShowToastCreated] = useState(false);
  //const [showDatePickerModal, setShowDatePickerModal] = useState(false);
  const [financeActivity, setFinanceActivity] = useState<FinanceActivity>({
    _id: "",
    user: "",
    projectId: "",
    activity: "",
    description: "",
    date: newDate.toISOString(),
    amount: 0,
    expense: false,
    notes: "",
  });
  const [project, setProject] = useState<Project>({
    _id: "",
    userId: "",
    name: "",
    description: "",
    enableEmbedding: false,
    totalHours: 0,
    totalIncome: 0,
    totalExpenses: 0,
  });
  const [filteredTaskList, setFilteredTaskList] = useState<string[]>([]);
  const [filteredSubTaskList, setFilteredSubTaskList] = useState<string[]>([]);

  /*const [date, setDate] = useState(
      financeActivity.date !== new Date(+0)
        ? formatDate(financeActivity.date.toISOString())
        : ""
    );*/

  const projectId = financeActivity.projectId;
  const activity = financeActivity.activity;
  const description = financeActivity.description;

  const date = financeActivity.date;
  const amount = financeActivity.amount;
  const notes = financeActivity.notes;

  //setDate(financeActivity.date.toDateString());

  let systemDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

  const main = async () => {
    const content = document.querySelector("ion-content.scrollbarProjectItems");
    const styles = document.createElement("style");

    styles.textContent = `
                ::-webkit-scrollbar {
                  width: 5px;
                }
            
                /* Track */
                ::-webkit-scrollbar-track {
                  box-shadow: 0 0 5px grey; 
                  border-radius: 10px;
                }
            
                /* Handle */
                ::-webkit-scrollbar-thumb {
                  background: lightgrey; 
                  border-radius: 10px;
                }
            
                /* Handle on hover */
                ::-webkit-scrollbar-thumb:hover {
                  background: #bdc9bd; 
                }
              `;
    if (content !== null && content.shadowRoot !== null) {
      content.shadowRoot.appendChild(styles);
    }
  };

  setTimeout(() => {
    main();
  }, 250);

  useEffect(() => {
    (async function loadData() {
      if (projectid !== "") {
        const filteredProjects = projects.filter(
          (proj) => proj._id === projectid
        );
        if (filteredProjects.length > 0) {
          setFinanceActivity({
            ...financeActivity,
            projectId: projectid,
          });

          setProject(filteredProjects[0]);
          filterTaskList(filteredProjects[0]._id);
          filterSubTaskList(filteredProjects[0]._id, activity);
        }
      }
    })();
    //console.log("useEffect triggered");
    //systemDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  }, [project, projectid]);

  const filterTaskList = (projId: string) => {
    if (projectFinanceTaskList !== undefined) {
      const filteredTasks = projectFinanceTaskList.filter(
        (task) => task.projectId === projId
      );
      let tasks = [] as string[];
      for (let task of filteredTasks) {
        if (!tasks.includes(task.activity)) {
          tasks.push(task.activity);
        }
      }
      setFilteredTaskList(tasks);
    }
  };

  const filterSubTaskList = (projId: string, activity: string) => {
    if (projectFinanceTaskList !== undefined) {
      const filteredTasks = projectFinanceTaskList.filter(
        (task) => task.projectId === projId && task.activity === activity
      );
      let tasks = [] as string[];
      for (let task of filteredTasks) {
        if (!tasks.includes(task.description)) {
          tasks.push(task.description);
        }
      }
      setFilteredSubTaskList(tasks);
    }
  };

  const checkFinanceActivity = () => {
    /*if (amount < 0) {
      setFinanceActivity({
        ...financeActivity,
        amount: Math.abs(amount),
      });
    }*/

    if (projectId) return true;

    return false;
  };

  const closeToast = () => {
    setShowToastCreated(false);
    loadProjectData();
    history.push("/home");
  };

  const saveFinanceActivity = async () => {
    if (checkFinanceActivity()) {
      addNewFinanceActivity(financeActivity);
      setShowToastCreated(true);

      setFinanceActivity({
        _id: "",
        user: "",
        projectId: financeActivity.projectId,
        activity: "",
        description: "",
        date: newDate.toISOString(),
        amount: 0,
        expense: false,
        notes: "",
      });
    }
  };

  const onChangeActivity = (fieldValue: string) => {
    setFinanceActivity({
      ...financeActivity,
      ["activity"]: fieldValue,
    });
    filterSubTaskList(projectId, fieldValue);
  };

  const onChangeDescription = (fieldValue: string) => {
    setFinanceActivity({
      ...financeActivity,
      ["description"]: fieldValue,
    });
  };

  const onChange = (fieldName: string, fieldValue: string) => {
    setFinanceActivity({
      ...financeActivity,
      [fieldName]: fieldValue,
    });
  };

  const onChangeProject = (fieldValue: Project) => {
    //console.log(fieldValue)
    if (fieldValue !== undefined) {
      if (fieldValue._id === "") {
        const filteredProjects = projects.filter(
          (project) => project.name === fieldValue.name
        );
        //console.log(filteredProjects[0])
        //onChanged(filteredProjects[0]);
        if (filteredProjects.length > 0) {
          setFinanceActivity({
            ...financeActivity,
            projectId: filteredProjects[0]._id,
          });
          setProject(filteredProjects[0]);
          filterTaskList(filteredProjects[0]._id);
          filterSubTaskList(filteredProjects[0]._id, activity);
        } else {
          setFinanceActivity({
            ...financeActivity,
            projectId: fieldValue._id,
          });
          setProject(fieldValue);
          filterTaskList(fieldValue._id);
          filterSubTaskList(fieldValue._id, activity);
        }
      } else {
        setFinanceActivity({
          ...financeActivity,
          projectId: fieldValue._id,
        });
        setProject(fieldValue);
        filterTaskList(fieldValue._id);
        filterSubTaskList(fieldValue._id, activity);
      }
    }
  };

  const onChangeDatePicker = (dateValue: string) => {
    let thisDate = new Date(dateValue);
    //console.log(thisDate.toISOString());
    //setDate(dateValue);

    setFinanceActivity({
      ...financeActivity,
      date: thisDate.toISOString(),
    });
    //setShowDatePickerModal(false);
  };

  return (
    <IonPage id="projectDetails">
      <IonHeader>
        <IonToolbar color="secondary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Track an income for a project</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent class="scrollbarProjectItems">
        <IonItem className={systemDark ? "" : "ion-item-background"}>
          <IonLabel>Select project:</IonLabel>
          <SelectProjectModal
            project={project}
            onChanged={onChangeProject}
            slot="end"
          />
        </IonItem>
        <AutoSuggestString
          label="Select task:"
          value={activity}
          dataset={filteredTaskList}
          disabled={false}
          onChange={onChangeActivity}
        />
        <IonItem
          id="open-date-input"
          className={systemDark ? "" : "ion-item-background"}
        >
          <IonLabel>Select date:</IonLabel>

          <IonPopover trigger="open-date-input" showBackdrop={false}>
            <IonDatetime
              presentation="date"
              onIonChange={(ev) => onChangeDatePicker(ev.detail.value!)}
              showDefaultButtons={true}
            />
          </IonPopover>
          <IonInput value={formatDate(date)}></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel>Amount:</IonLabel>
          <IonInput
            type="number"
            inputMode="decimal"
            value={amount}
            placeholder="Amount"
            onIonChange={(e) => onChange("amount", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <AutoSuggestString
          label="Description:"
          value={description}
          dataset={filteredSubTaskList}
          disabled={false}
          onChange={onChangeDescription}
        />
        <IonItem className={systemDark ? "" : "ion-item-background"}>
          <IonLabel>Notes:</IonLabel>
          <IonInput
            value={notes}
            onIonChange={(e) => onChange("notes", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonButton
            //routerLink={`/Projects`}
            //routerDirection="none"
            onClick={saveFinanceActivity}
            slot="end"
          >
            Save and close
          </IonButton>
          <IonButton routerLink={`/Home`} routerDirection="none" slot="end">
            Cancel
          </IonButton>
        </IonItem>
        <IonToast
          isOpen={showToastCreated}
          onDidDismiss={() => closeToast()}
          message={`Your income has been tracked!`}
          duration={1500}
          color="primary"
        />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    //isAuthenticated: state.user.isAuthenticated,
    //error: state.user.error,
    projects: state.projectData.projects,
    user: state.user.user,
    projectFinanceTaskList: state.projectData.projectFinanceTaskList,
  }),
  mapDispatchToProps: {
    addNewFinanceActivity,
    loadProjectData,
  },
  component: NewIncomeActivityPage,
});
