import React, { useState, useEffect } from "react";
import {
  //IonHeader,
  //IonToolbar,
  IonTitle,
  IonContent,
  //IonPage,
  //IonButtons,
  //IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
} from "@ionic/react";
import "./Login.scss";
import { LoginDetails } from "../../models/User";
import { login, clearError } from "../../data/user/user.actions";
import { connect } from "../../data/connect";
import { useLocation, RouteComponentProps } from "react-router";
import DemoLoginDetails from "./DemoLoginDetails";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {
  login: typeof login;
  clearError: typeof clearError;
}

interface StateProps {
  isAuthenticated: boolean;
  error: string;
}

interface LoginProps extends OwnProps, StateProps, DispatchProps {}

const DemoLoginButton: React.FC<LoginProps> = ({
  login,
  clearError,
  isAuthenticated,
  error,
  history,
}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [loginDetails, setLoginDetails] = useState<LoginDetails>({
    email: "demo@shoppingtracker.app",
    password: "123456",
  });
  const location = useLocation();
  //console.log(location);

  const email = loginDetails.email;
  const password = loginDetails.password;

  useEffect(() => {
    if (isAuthenticated) {
      //if (history.length > 1) history.goBack();
      //.go(1);
      //history.length - 2);
      //else
      //history.push("/");
    }

    if (error === "Invalid Credentials") {
      clearError();
    }
  }, [error, isAuthenticated, history]);

  const loginUser = async () => {
    setFormSubmitted(true);
    if (!email) {
      setEmailError(true);
    }
    if (!password) {
      setPasswordError(true);
    }

    if (email && password) {
      await login(loginDetails);
      history.push("/home", { direction: "none" });
    }
  };

  return (
    <>
      <IonButton expand="block" fill="solid" onClick={() => loginUser()}>
        Click here to try the Demo account
      </IonButton>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    isAuthenticated: state.user.isAuthenticated,
    error: state.user.error,
  }),
  mapDispatchToProps: {
    login,
    clearError,
  },
  component: React.memo(DemoLoginButton),
});
