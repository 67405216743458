import { ProjectsActions } from "./projects.actions";
import { ProjectsState } from "./projects.state";

export const projectsReducer = (
  state: ProjectsState,
  action: ProjectsActions
): ProjectsState => {
  switch (action.type) {
    case "set-projects-loading": {
      return { ...state, loading: action.isLoading };
    }
    case "set-projects-data": {
      return { ...state, ...action.data };
    }
    case "get-project": {
      return { ...state, currentProject: action.data };
    }
    case "add-project": {
      return { ...state, projects: [...state.projects, action.data] };
    }
    case "update-project": {
      return {
        ...state,
        projects: state.projects.map((project) =>
          project._id === action.data._id ? action.data : project
        ),
      };
    }
    case "delete-project": {
      return {
        ...state,
        projects: state.projects.filter(
          (project) => project._id !== action.data._id
        ),
      };
    }
    case "set-project-time-tracked-chart-data": {
      return {
        ...state,
        projectTimeTrackedChartData: action.chartData,
      };
    }
    case "set-project-time-tracked-chart-loading": {
      return {
        ...state,
        projectTimeTrackedChartLoading: action.loading,
      };
    }
    case "set-project-total-time-tracked-chart-data": {
      return {
        ...state,
        projectTotalTimeTrackedChartData: action.chartData,
      };
    }
    case "set-project-total-time-tracked-chart-loading": {
      return {
        ...state,
        projectTotalTimeTrackedChartLoading: action.loading,
      };
    }
    case "set-project-profit-loss-chart-data": {
      return {
        ...state,
        projectProfitLossChartData: action.chartData,
      };
    }
    case "set-project-profit-loss-chart-loading": {
      return {
        ...state,
        projectProfitLossChartLoading: action.loading,
      };
    }
    case "set-project-profit-loss-per-hour-chart-data": {
      return {
        ...state,
        projectProfitLossPerHourChartData: action.chartData,
      };
    }
    case "set-project-profit-loss-per-hour-chart-loading": {
      return {
        ...state,
        projectProfitLossPerHourChartLoading: action.loading,
      };
    }
    case "set-project-time-per-task-chart-data": {
      return {
        ...state,
        projectTimePerTaskChartData: action.chartData,
      };
    }
    case "set-project-time-per-task-chart-loading": {
      return {
        ...state,
        projectTimePerTaskChartLoading: action.loading,
      };
    }
    case "set-project-time-per-project-chart-data": {
      return {
        ...state,
        projectTimePerProjectChartData: action.chartData,
      };
    }
    case "set-project-time-per-project-chart-loading": {
      return {
        ...state,
        projectTimePerProjectChartLoading: action.loading,
      };
    }
    case "set-project-time-per-task-grid-data": {
      return {
        ...state,
        projectTimePerTaskGridData: [...action.projectTimePerTaskGridData],
      };
    }
    case "set-project-time-per-task-grid-loading": {
      return {
        ...state,
        projectTimePerTaskGridLoading: action.loading,
      };
    }
    case "set-project-time-per-sub-task-grid-data": {
      return {
        ...state,
        projectTimePerSubTaskGridData: [
          ...action.projectTimePerSubTaskGridData,
        ],
      };
    }
    case "set-project-time-per-sub-task-grid-loading": {
      return {
        ...state,
        projectTimePerSubTaskGridLoading: action.loading,
      };
    }
    case "set-project-task-list-data": {
      return {
        ...state,
        projectTaskList: [...action.projectTaskList],
      };
    }
    case "set-project-finance-task-list-data": {
      return {
        ...state,
        projectFinanceTaskList: [...action.projectTaskList],
      };
    }
    case "update-filtered-items": {
      return { ...state, filteredItems: action.filteredItems };
    }
    case "set-search-text": {
      return { ...state, searchText: action.searchText };
    }
  }
};
