import { IonItemGroup, IonList, IonListHeader } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { TimeActivity } from "../../models/TimeActivity";
import TimeActivityItem from "./TimeActivityItem";
import { connect } from "../../data/connect";

interface OwnProps {
  timeActivities: TimeActivity[];
  hide: boolean;
}

interface StateProps {}

interface DispatchProps {}

interface TimeActivityListProps extends OwnProps, StateProps, DispatchProps {}

const TimeActivityList: React.FC<TimeActivityListProps> = ({
  timeActivities,
  hide,
}) => {
  let systemDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

  const [listItems, setListItems] = useState<TimeActivity[]>(
    timeActivities.slice(0, 4)
  );

  const recursive = () => {
    setTimeout(() => {
      if (listItems.length < timeActivities.length) {
        setListItems(
          timeActivities.slice(0, listItems.length + 1) //length)
        );
      } else if (listItems.length > timeActivities.length) {
        setListItems(timeActivities.slice(0, 4));
      }
    }, 0);
  };

  useEffect(() => {
    recursive();
  }, [listItems, timeActivities]);

  if (timeActivities.length === 0 && !hide) {
    return (
      <IonList>
        <IonListHeader>No activities found</IonListHeader>
      </IonList>
    );
  }

  return (
    <>
      <IonList style={hide ? { display: "none" } : {}}>
        {listItems.map((timeActivity, index: number) => (
          <IonItemGroup key={`group-${index}`}>
            <TimeActivityItem timeActivity={timeActivity}></TimeActivityItem>
          </IonItemGroup>
        ))}
      </IonList>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: TimeActivityList,
});
