import {
  IonTitle,
  IonButton,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { connect } from "../../data/connect";
import { RouteComponentProps } from "react-router";
import { Project } from "../../models/Project";
import { User } from "../../models/User";

import TimeActivityList from "../timeActivities/TimeActivityList";
import { TimeActivity } from "../../models/TimeActivity";
import { getTimeActivities } from "../../data/timeActivities/timeActivities.actions";

//import DatePickerModal from "../layout/DatePickerModal";
//import moment from "moment";
import "../../theme/styling.scss";
//import { filter } from "ionicons/icons";

interface OwnProps /*extends RouteComponentProps*/ {
  project: Project;
}

interface DispatchProps {
  getTimeActivities: typeof getTimeActivities;
}

interface StateProps {
  timeActivities: TimeActivity[];
  user: User;
}

interface TimeActivityGridProps extends OwnProps, DispatchProps, StateProps {}

const TimeActivityGrid: React.FC<TimeActivityGridProps> = ({
  project,
  timeActivities,
  user,
  getTimeActivities,
}) => {
  const [showActivities, setShowActivities] = useState(false);
  const [projectId, setProjectId] = useState(project._id);

  const main = async () => {
    const content = document.querySelector("ion-content.timeActivityList");
    const styles = document.createElement("style");

    styles.textContent = `
                ::-webkit-scrollbar {
                  width: 5px;
                }
            
                /* Track */
                ::-webkit-scrollbar-track {
                  box-shadow: 0 0 5px grey; 
                  border-radius: 10px;
                }
            
                /* Handle */
                ::-webkit-scrollbar-thumb {
                  background: lightgrey; 
                  border-radius: 10px;
                }
            
                /* Handle on hover */
                ::-webkit-scrollbar-thumb:hover {
                  background: #bdc9bd; 
                }
              `;
    if (content !== null && content.shadowRoot !== null) {
      content.shadowRoot.appendChild(styles);
    }
  };

  setTimeout(() => {
    main();
  }, 250);

  useEffect(() => {
    (async function loadData() {
      //console.log(project);
      await getTimeActivities(project);
    })();
    setProjectId(project._id);
  }, [project]);

  const toggleShowActivities = () => {
    setShowActivities(!showActivities);
  };

  return (
    <>
      <IonItem onClick={toggleShowActivities} button={true} color="secondary">
        <IonTitle>{showActivities ? " -" : " +"} Activities </IonTitle>
        <IonButton
          //expand="block"
          routerLink={`/newtimeactivity/${projectId}`}
          routerDirection="none"
        >
          Track time
        </IonButton>
      </IonItem>
      <IonItem hidden={!showActivities}>
        <IonGrid>
          <IonRow>
            <IonCol size="2" sizeSm="2">
              Date
            </IonCol>
            <IonCol size="5" sizeSm="3">
              Task
            </IonCol>
            <IonCol sizeSm="2" className="ion-hide-sm-down">
              Sub-Task
            </IonCol>
            <IonCol size="1" className="ion-hide-sm-down">
              Start time
            </IonCol>
            <IonCol size="1" className="ion-hide-sm-down">
              End time
            </IonCol>
            <IonCol size="3" sizeSm="1">
              Total time
            </IonCol>
            <IonCol size="2"></IonCol>
          </IonRow>
        </IonGrid>
      </IonItem>
      <TimeActivityList
        timeActivities={timeActivities}
        hide={!showActivities}
      />
    </>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.user.user,
    timeActivities: state.timeActivityData.timeActivities,
  }),
  mapDispatchToProps: {
    getTimeActivities,
  },
  component: TimeActivityGrid,
});
