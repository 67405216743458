import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  //IonItem,
  IonButton,
  IonText,
  IonProgressBar,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonLabel,
} from "@ionic/react";
import React from "react";
import {
  helpOutline,
  listOutline,
  receiptOutline,
  statsChartOutline,
} from "ionicons/icons";
import "./Home.scss";
//import { useParams } from "react-router";
//import { AddBtn } from "../components/layout/AddBtn";
import LoginComponent from "../components/auth/LoginComponent";
//import DemoLoginDetails from "../components/auth/DemoLoginDetails"
//import { Login } from "./Login";
import { connect } from "../data/connect";
import { useLocation, RouteComponentProps } from "react-router";
import { Footer } from "../components/layout/Footer";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {}

interface StateProps {
  isAuthenticated: boolean;
  hasSeenTutorial: boolean;
  error: string;
  loading: boolean;
}

interface HomeProps extends OwnProps, DispatchProps, StateProps {}

const Home: React.FC<HomeProps> = ({
  isAuthenticated,
  hasSeenTutorial,
  error,
  loading,
  history,
  location,
  match,
}) => {
  //const { name } = useParams<{ name: string }>();
  //className={`${darkMode ? "content-block" : ""}`}
  return (
    <IonPage id="background">
      <IonHeader>
        <IonToolbar hidden={!isAuthenticated} color="secondary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Metric Soup</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <br />
        <IonTitle hidden={!isAuthenticated} size="large">
          <h1>Welcome!</h1>
        </IonTitle>

        <br />
        {loading && isAuthenticated && (
          <IonText color="primary">
            <p className="ion-padding-start">Loading...</p>
          </IonText>
        )}
        <IonProgressBar
          type="indeterminate"
          hidden={!loading || !isAuthenticated}
        ></IonProgressBar>
        <br />
        <LoginComponent history={history} location={location} match={match} />
        <div className="home-page" id="home-page">
          <IonGrid hidden={!isAuthenticated}>
            <IonRow>
              <IonCol sizeSm="4">
                <IonButton
                  expand="block"
                  routerLink={"/dashboard"}
                  routerDirection="none"
                  hidden={!isAuthenticated}
                  size="large"
                  class="button-icon-top"
                >
                  <div>
                    <IonIcon
                      size="large"
                      slot="start"
                      color="primary-shade"
                      icon={statsChartOutline}
                      class="button-icon"
                      //style={"[zoom]:2.0;"}
                    />
                    <br />
                    <IonLabel class="ion-text-wrap">
                      Go to my Dashboard!
                    </IonLabel>
                  </div>
                </IonButton>
              </IonCol>
              <IonCol sizeSm="4">
                <IonButton
                  expand="block"
                  routerLink={"/tracktimeactivity"}
                  routerDirection="none"
                  hidden={!isAuthenticated}
                  size="large"
                  class="button-icon-top"
                >
                  <div>
                    <IonIcon
                      slot="start"
                      color="primary-shade"
                      icon={helpOutline}
                      size="large"
                      class="button-icon"
                    />
                    <br />
                    <IonLabel class="ion-text-wrap">Track time</IonLabel>
                  </div>
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol sizeSm="4">
                <IonButton
                  expand="block"
                  routerLink={"/trackincomeactivity"}
                  routerDirection="none"
                  hidden={!isAuthenticated}
                  size="large"
                  class="button-icon-top"
                >
                  <div>
                    <IonIcon
                      slot="start"
                      color="primary-shade"
                      icon={listOutline}
                      size="large"
                      class="button-icon"
                    />
                    <br />
                    <IonLabel class="ion-text-wrap">Track income</IonLabel>
                  </div>
                </IonButton>
              </IonCol>
              <IonCol sizeSm="4">
                <IonButton
                  expand="block"
                  routerLink={"/trackexpenseactivity"}
                  routerDirection="none"
                  hidden={!isAuthenticated}
                  size="large"
                  class="button-icon-top"
                >
                  <div>
                    <IonIcon
                      slot="start"
                      color="primary-shade"
                      icon={receiptOutline}
                      size="large"
                      class="button-icon"
                    />
                    <br />
                    <IonLabel class="ion-text-wrap">Track expense</IonLabel>
                  </div>
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
      <Footer hide={false} />
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    isAuthenticated: state.user.isAuthenticated,
    hasSeenTutorial: state.user.hasSeenTutorial,
    error: state.user.error,
    loading: state.user.loading,
  }),
  mapDispatchToProps: {},
  component: Home,
});
