import React, { useEffect, useState } from "react";
import { IonButton, IonIcon } from "@ionic/react";
import { play, pause } from "ionicons/icons";
import { formatFullDuration } from "../../utils/dateTimeFormat";

interface OwnProps {
  duration: number;
  reset: boolean;
  setDuration: (duration: number) => void;
  setTimerRunning: (timerRunning: boolean) => void;
  setReset: (reset: boolean) => void;
}

interface TimerButtonProps extends OwnProps {}

export const TimerButtonEnhanced: React.FC<TimerButtonProps> = ({
  duration,
  reset,
  setDuration,
  setTimerRunning,
  setReset,
}) => {
  const [running, setRunning] = useState(false);
  const [startTime, setStartTime] = useState(new Date().getTime());
  const [runningDuration, setRunningDuration] = useState(duration);

  const recursive = () => {
    setTimeout(() => {
      if (running) {
        //console.log(`duration: ${duration}`);
        //console.log(`calc duration: ${calculateDuration()}`);
        setRunningDuration(duration + calculateDuration());
      }
    }, 1000);
  };

  useEffect(() => {
    recursive();

    if (reset) {
      setRunningDuration(0);
      setReset(!reset);
    }
  }, [running, runningDuration, reset]);

  const onClicked = () => {
    if (!running) {
      setStartTime(new Date().getTime());
    } else {
      setDuration(duration + calculateDuration());
    }
    setTimerRunning(!running);
    setRunning(!running);
  };

  const calculateDuration = () => {
    let currentTime = new Date();
    let currentDuration = 0;
    currentDuration = currentTime.getTime() - startTime;

    return currentDuration;
  };

  return (
    <IonButton expand="block" size="large" onClick={onClicked}>
      {formatFullDuration(runningDuration)}
      <br />
      <IonIcon icon={running ? pause : play} size="large"></IonIcon>
    </IonButton>
  );
};
