import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
  //IonItem,
  //IonButton,
  //IonText,
  //IonProgressBar ,
} from "@ionic/react";
import React from "react";
import "./Home.scss";
//import { useParams } from "react-router";
//import { AddBtn } from "../components/layout/AddBtn";
//import LoginComponent from "../components/auth/LoginComponent"
//import DemoLoginDetails from "../components/auth/DemoLoginDetails"
//import { Login } from "./Login";
import { connect } from "../data/connect";
import { RouteComponentProps } from "react-router";
import { Footer } from "../components/layout/Footer";
import LifetimeMembershipControl from "../components/layout/LifetimeMemberControl";
import FreemiumComparisonGrid from "../components/layout/FreemiumComparisonGrid";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {}

interface StateProps {}

interface HomeProps extends OwnProps, DispatchProps, StateProps {}

const Subscribe: React.FC<HomeProps> = ({}) => {
  //const { name } = useParams<{ name: string }>();

  return (
    <IonPage id="subscriptionPage">
      <IonHeader>
        <IonToolbar color="secondary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Subscribe</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <br />
        <LifetimeMembershipControl />
        <IonText color="danger" className="ion-text-center">
          <h2>
            <b>Coming soon!</b>
          </h2>
        </IonText>
        <br />
        <FreemiumComparisonGrid />
        <br />
        <div className="ion-text-center">
          If you can't wait and need a premium account now, then please send an
          email to info@shoppingtracker.app <br />
        </div>
      </IonContent>
      <Footer hide={false} />
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: Subscribe,
});
