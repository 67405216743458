import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { connect } from "../../data/connect";
import { RouteComponentProps } from "react-router";
import { Project } from "../../models/Project";
import { User } from "../../models/User";

import FinanceActivityList from "../financeActivities/FinanceActivityList";
import { FinanceActivity } from "../../models/FinanceActivity";
import { getFinanceActivities } from "../../data/financeActivities/financeActivities.actions";

//import DatePickerModal from "../layout/DatePickerModal";
//import moment from "moment";
import "../../theme/styling.scss";
//import { filter } from "ionicons/icons";

interface OwnProps /*extends RouteComponentProps*/ {
  project: Project;
}

interface DispatchProps {
  getFinanceActivities: typeof getFinanceActivities;
}

interface StateProps {
  financeActivities: FinanceActivity[];
  user: User;
}

interface FinanceActivityGridProps
  extends OwnProps,
    DispatchProps,
    StateProps {}

const FinanceActivityGrid: React.FC<FinanceActivityGridProps> = ({
  project,
  financeActivities,
  user,
  getFinanceActivities,
}) => {
  const [showActivities, setShowActivities] = useState(false);

  const main = async () => {
    const content = document.querySelector("ion-content.financeActivityList");
    const styles = document.createElement("style");

    styles.textContent = `
                  ::-webkit-scrollbar {
                    width: 5px;
                  }
              
                  /* Track */
                  ::-webkit-scrollbar-track {
                    box-shadow: 0 0 5px grey; 
                    border-radius: 10px;
                  }
              
                  /* Handle */
                  ::-webkit-scrollbar-thumb {
                    background: lightgrey; 
                    border-radius: 10px;
                  }
              
                  /* Handle on hover */
                  ::-webkit-scrollbar-thumb:hover {
                    background: #bdc9bd; 
                  }
                `;
    if (content !== null && content.shadowRoot !== null) {
      content.shadowRoot.appendChild(styles);
    }
  };

  setTimeout(() => {
    main();
  }, 250);

  useEffect(() => {
    (async function loadData() {
      await getFinanceActivities(project);
    })();
  }, [project]);

  const toggleShowActivities = () => {
    setShowActivities(!showActivities);
  };

  return (
    <>
      <IonItem onClick={toggleShowActivities} button={true} color="secondary">
        <IonTitle>{showActivities ? " -" : " +"} Income/expenses </IonTitle>
        <IonButton
          //expand="block"
          routerLink={`/newincomeactivity/${project._id}`}
          routerDirection="none"
        >
          Track income
        </IonButton>
        <IonButton
          //expand="block"
          routerLink={`/newexpenseactivity/${project._id}`}
          routerDirection="none"
        >
          Track expense
        </IonButton>
      </IonItem>
      <IonItem hidden={!showActivities}>
        <IonGrid>
          <IonRow>
            <IonCol size="2" sizeSm="2">
              Date
            </IonCol>
            <IonCol size="3" sizeSm="3">
              Task
            </IonCol>
            <IonCol size="2" className="ion-hide-sm-down">
              Sub-Task
            </IonCol>
            <IonCol size="3" sizeSm="1">
              Amount
            </IonCol>
            <IonCol size="2" className="ion-hide-sm-down">
              Notes
            </IonCol>
            <IonCol size="2"></IonCol>
          </IonRow>
        </IonGrid>
      </IonItem>
      <FinanceActivityList
        financeActivities={financeActivities}
        hide={!showActivities}
      />
    </>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.user.user,
    financeActivities: state.financeActivityData.financeActivities,
  }),
  mapDispatchToProps: {
    getFinanceActivities,
  },
  component: FinanceActivityGrid,
});
