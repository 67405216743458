import { format, parseISO } from "date-fns";

export const formatDate = (value: string) => {
  if (value !== null) {
    return value === "" ? "" : format(parseISO(value), "dd MMM yyyy"); //"yyyy-MM-dd");
  }
  return "";
};

export const formatTime = (value: string) => {
  if (value !== null) {
    return value === "" ? "" : format(parseISO(value), "HH:mm"); //"yyyy-MM-dd");
  }
  return "";
};

function pad(n: number, z: number) {
  z = z || 2;
  return ("00" + n).slice(-z);
}

export const formatDuration = (value: number) => {
  var s = value;
  var ms = s % 1000;
  s = (s - ms) / 1000;
  var secs = s % 60;
  s = (s - secs) / 60;
  var mins = s % 60;
  var hrs = (s - mins) / 60;

  return pad(hrs, 2) + ":" + pad(mins, 2);
};

export const formatFullDuration = (value: number) => {
  var s = value;
  var ms = s % 1000;
  s = (s - ms) / 1000;
  var secs = s % 60;
  s = (s - secs) / 60;
  var mins = s % 60;
  var hrs = (s - mins) / 60;

  return pad(hrs, 2) + ":" + pad(mins, 2) + ":" + pad(secs, 2);
};
