import {
  //getUserData,
  setIsLoggedInData,
  //setUsernameData,
  setHasSeenTutorialData,
  //loadData,
} from "../dataApi";
import { ActionType } from "../../utils/types";
import { UserState } from "./user.state";
import setAuthToken from "../../utils/setAuthToken";
import {
  User,
  LoginDetails,
  RegisterUser,
  ResetPasswordDetails,
} from "../../models/User";
import axios from "axios";
import { exportToJsonFile } from "../../utils/fileHandler";
//import { ContinuousColorLegend } from "react-vis";

export const loadUserData = () => async (dispatch: React.Dispatch<any>) => {
  //dispatch(setLoading(true));
  //const data = await getUserData();
  //dispatch(setData(data));
  const user = await loadUser();
  if (user === "error") {
    dispatch(loginFailure);
  } else {
    dispatch(setUser(user));
  }
  //await loadData();
  //dispatch(setLoading(false));
};

export const loadUser = async () => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    //const res = await fetch(`/api/users/${userId}`);
    const res = await axios.get("/api/auth");
    //console.log(res)
    const result = await res.data;
    //console.info(result);

    //dispatch(setUser(result));

    return result;
  } catch (error) {
    console.log("Error in users.actions -> loadUser");
    if (error instanceof Error) {
      console.log(error.message);
    }
    //console.log(error)
    //dispatch(loginFailure(error));
    return "error";
  }
};

export const setUser = (user: User) =>
  ({
    type: "set-user",
    user,
  } as const);

export const register =
  (data: RegisterUser) => async (dispatch: React.Dispatch<any>) => {
    try {
      //console.info(data);
      const res = await fetch("/api/users", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await res.json();
      //console.log(result.token);
      //setAuthToken(result.token)

      dispatch(registerSuccess(result.token));
      const userResult = await loadUser();
      //console.log(userResult);
      if (userResult === "error") {
        dispatch(loginFailure(userResult));
      } else {
        dispatch(setUser(userResult));
      }
    } catch (error) {
      console.log("Error in users.actions -> register");
      if (error instanceof Error) {
        dispatch(registerFailure(error.message));
      }
    }
  };

export const registerSuccess = (token: string) =>
  ({
    type: "register-user-success",
    token,
  } as const);

export const registerFailure = (error: string) =>
  ({
    type: "register-user-failure",
    error,
  } as const);

export const login =
  (data: LoginDetails) => async (dispatch: React.Dispatch<any>) => {
    try {
      //console.info(data);
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post("/api/auth", data, config);
      //console.log(res);
      const result = await res.data.token;
      //const userId = await res.data.payload.user.id;
      //console.info(result);

      dispatch(loginSuccess(result));

      //console.log("Before load user");
      //console.log(userId);
      const userResult = await loadUser();
      //console.log(userResult);
      dispatch(setUser(userResult));
      //console.log("After loadUser");
    } catch (error) {
      console.log("Error in users.actions -> login");
      //console.log(error)
      dispatch(
        loginFailure("Login failed. Please check your login credentials")
      );
    }
  };

export const deleteUser = () => async (dispatch: React.Dispatch<any>) => {
  try {
    //console.info(data);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.delete("/api/users", config);
    //console.log(res);

    await setIsLoggedInData(false);
    dispatch(logout());
  } catch (error) {
    console.log("Error in users.actions -> delete");
    //console.log(error)
    dispatch(loginFailure("Deletion failed. User's data has not been deleted"));
  }
};

export const loginSuccess = (token: string) =>
  ({
    type: "login-success",
    token,
  } as const);

export const loginFailure = (error: string) =>
  ({
    type: "login-failure",
    error,
  } as const);

export const setLoading = (isLoading: boolean) =>
  ({
    type: "set-user-loading",
    isLoading,
  } as const);

export const setData = (data: Partial<UserState>) =>
  ({
    type: "set-user-data",
    data,
  } as const);

export const logoutUser = () => async (dispatch: React.Dispatch<any>) => {
  //logout
  await setIsLoggedInData(false);
  dispatch(logout());
};

export const logout = () =>
  ({
    type: "logout",
  } as const);

export const setIsLoggedIn =
  (loggedIn: boolean) => async (dispatch: React.Dispatch<any>) => {
    await setIsLoggedInData(loggedIn);
    return {
      type: "set-is-loggedin",
      loggedIn,
    } as const;
  };

export const setHasSeenTutorial =
  (hasSeenTutorial: boolean) => async (dispatch: React.Dispatch<any>) => {
    await setHasSeenTutorialData(hasSeenTutorial);
    return {
      type: "set-has-seen-tutorial",
      hasSeenTutorial,
    } as const;
  };

export const editUserSettings =
  (
    currency: string,
    numberFormat: string,
    enableEmbedding: boolean,
    showPoweredBy: boolean
  ) =>
  async (dispatch: React.Dispatch<any>) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      //console.info(data);
      const res = await axios.post(
        `/api/usersettings/`,
        [currency, numberFormat, enableEmbedding, showPoweredBy],
        config
      );
      const result = await res.data;
      //console.info(result);

      dispatch(updateUserSettings(result));
    } catch {
      console.log("Error in user.actions -> editUserSettings");
    }
  };

export const updateUserSettings = (data: User) =>
  ({
    type: "update-user-settigs",
    data,
  } as const);

// Forgot password
export const forgotPassword =
  (data: string) => async (dispatch: React.Dispatch<any>) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      //console.log(data)

      const res = await axios.post(
        "/api/forgotpassword",
        { email: data },
        config
      );

      const result = await res.data;

      dispatch(startForgotPassword(true));
    } catch (error) {
      console.log("Error in user.actions -> forgotPassword");
    }
  };

// Check password reset token
export const checkPasswordResetToken =
  (token: string) => async (dispatch: React.Dispatch<any>) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.get(`/api/resetpassword/${token}`, config);

      const result = await res.data;

      dispatch(checkResetToken(result));
    } catch (error) {
      console.log("Error in user.actions -> checkPasswordResetToken");
    }
  };

// Reset password
export const resetPassword =
  (data: ResetPasswordDetails) => async (dispatch: React.Dispatch<any>) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.put("/api/resetpassword", data, config);

      const result = await res.data;

      dispatch(updatePassword(result));
    } catch (error) {
      console.log("Error in user.actions -> resetPassword");
    }
  };

// Export user data
export const exportUserData = () => async (dispatch: React.Dispatch<any>) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.get(`/api/exportuserdata/`, config);

    const result = await res.data;

    console.log(result);

    exportToJsonFile(result, "My Metric Soup data");

    dispatch(resetPassword(result));
  } catch (error) {
    if (error instanceof Error) {
      console.log(error.message); //.response.data);
    }

    console.log("Error in user.actions -> exportUserData");
  }
};

// Check email confirm token
export const checkEmailConfirmToken =
  (token: string) => async (dispatch: React.Dispatch<any>) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.get(`/api/confirmemail/${token}`, config);

      const result = await res.data;

      dispatch(setUser(result));
    } catch (error) {
      console.log("Error in user.actions -> checkEmailConfirmToken");
      if (error instanceof Error) {
        console.log(error.message);
      }
    }
  };

// Confirm email
export const confirmEmail =
  (data: string) => async (dispatch: React.Dispatch<any>) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      //console.log(data)

      const res = await axios.post(
        "/api/confirmemail",
        { email: data },
        config
      );

      const result = await res.data;
    } catch (error) {
      console.log("Error in user.actions -> confirmEmail");
    }
  };

// Claim a voucher code
export const claimVoucherCode =
  (code: string) => async (dispatch: React.Dispatch<any>) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(`/api/claimvouchercode/${code}`, config);

      const result = await res.data;
      //console.log(result);

      dispatch(claimCode(result));

      return result;
    } catch (error) {
      console.log("Error in users.actions -> claimVoucherCode");
      if (error instanceof Error) {
        console.log(error.message);
      }
      return "error";
    }
  };

export const startForgotPassword = (data: boolean) =>
  ({
    type: "forgot-password",
    data,
  } as const);

export const checkResetToken = (data: User) =>
  ({
    type: "check-reset-token",
    data,
  } as const);

export const updatePassword = (data: User) =>
  ({
    type: "reset-password",
    data,
  } as const);

export const checkEmailToken = (data: User) =>
  ({
    type: "check-email-confirm-token",
    data,
  } as const);

export const claimCode = (result: boolean) =>
  ({
    type: "claim-voucher-code",
    result,
  } as const);

export const setDarkMode = (darkMode: boolean) =>
  ({
    type: "set-dark-mode",
    darkMode,
  } as const);

export const clearError = () => ({ type: "clear-error" } as const);

export type UserActions =
  | ActionType<typeof setUser>
  | ActionType<typeof setLoading>
  | ActionType<typeof registerSuccess>
  | ActionType<typeof registerFailure>
  | ActionType<typeof loginSuccess>
  | ActionType<typeof loginFailure>
  | ActionType<typeof setData>
  | ActionType<typeof logout>
  | ActionType<typeof setIsLoggedIn>
  | ActionType<typeof setHasSeenTutorial>
  | ActionType<typeof updateUserSettings>
  | ActionType<typeof startForgotPassword>
  | ActionType<typeof checkResetToken>
  | ActionType<typeof updatePassword>
  | ActionType<typeof checkEmailToken>
  | ActionType<typeof claimCode>
  | ActionType<typeof setDarkMode>
  | ActionType<typeof clearError>;
