import { IonItemGroup, IonList, IonListHeader } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { FinanceActivity } from "../../models/FinanceActivity";
import FinanceActivityItem from "./FinanceActivityItem";
import { connect } from "../../data/connect";

interface OwnProps {
  financeActivities: FinanceActivity[];
  hide: boolean;
}

interface StateProps {}

interface DispatchProps {}

interface FinanceActivityListProps
  extends OwnProps,
    StateProps,
    DispatchProps {}

const FinanceActivityList: React.FC<FinanceActivityListProps> = ({
  financeActivities,
  hide,
}) => {
  let systemDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

  const [listItems, setListItems] = useState<FinanceActivity[]>(
    financeActivities.slice(0, 4)
  );

  const recursive = () => {
    setTimeout(() => {
      if (listItems.length < financeActivities.length) {
        setListItems(
          financeActivities.slice(0, listItems.length + 1) //length)
        );
      } else if (listItems.length > financeActivities.length) {
        setListItems(financeActivities.slice(0, 4));
      }
    }, 0);
  };

  useEffect(() => {
    recursive();
  }, [listItems, financeActivities]);

  if (financeActivities.length === 0 && !hide) {
    return (
      <IonList>
        <IonListHeader>No activities found</IonListHeader>
      </IonList>
    );
  }

  return (
    <>
      <IonList style={hide ? { display: "none" } : {}}>
        {listItems.map((financeActivity, index: number) => (
          <IonItemGroup key={`group-${index}`}>
            <FinanceActivityItem
              financeActivity={financeActivity}
            ></FinanceActivityItem>
          </IonItemGroup>
        ))}
      </IonList>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: FinanceActivityList,
});
