import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import React, { useEffect } from "react";

import { connect } from "../../data/connect";
import { RouteComponentProps } from "react-router";
import { Footer } from "../../components/layout/Footer";
import { Project } from "../../models/Project";
import ProjectList from "../projects/ProjectList";
import { loadProjectData } from "../../data/projects/projects.actions";
import { SingleAddBtn } from "../layout/SingleAddBtn";
import { TimeActivity } from "../../models/TimeActivity";
import { FinanceActivity } from "../../models/FinanceActivity";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {
  loadProjectData: typeof loadProjectData;
}

interface StateProps {
  isAuthenticated: boolean;
  error: string;
  projects: Project[];
  timeActivities: TimeActivity[];
  financeActivities: FinanceActivity[];
}

interface ProjectsPageProps extends OwnProps, DispatchProps, StateProps {}

const ProjectsPage: React.FC<ProjectsPageProps> = ({
  isAuthenticated,
  error,
  //history,
  projects,
  timeActivities,
  financeActivities,
}) => {
  //const { name } = useParams<{ name: string }>();
  //console.log(getPlatforms());
  useEffect(() => {
    loadProjectData();
    // eslint-disable-next-line
  }, [timeActivities, financeActivities]);

  const main = async () => {
    const content = document.querySelector("ion-content.scrollbarProject");
    const styles = document.createElement("style");

    styles.textContent = `
      ::-webkit-scrollbar {
        width: 5px;
      }
  
      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: 0 0 5px grey; 
        border-radius: 10px;
      }
  
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: lightgrey; 
        border-radius: 10px;
      }
  
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #bdc9bd; 
      }
    `;
    if (content !== null && content.shadowRoot !== null) {
      content.shadowRoot.appendChild(styles);
    }
  };

  setTimeout(() => {
    main();
  }, 250);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="secondary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Projects</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent class="scrollbarProject">
        <IonGrid>
          <IonRow>
            <IonCol size="2" sizeSm="2">
              Name
            </IonCol>
            <IonCol size="3" sizeSm="5">
              Description
            </IonCol>
            <IonCol size="2" sizeSm="1">
              Hours
            </IonCol>
            <IonCol size="1" className="ion-hide-sm-down">
              Income
            </IonCol>
            <IonCol size="1" className="ion-hide-sm-down">
              Expenses
            </IonCol>
            <IonCol size="3" sizeSm="1">
              Profit / Loss
            </IonCol>
            <IonCol size="1"></IonCol>
          </IonRow>
          <ProjectList projects={projects} hide={false} />
        </IonGrid>
      </IonContent>
      <SingleAddBtn source="Projects" />
      <Footer hide={false} />
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    isAuthenticated: state.user.isAuthenticated,
    error: state.user.error,
    projects: state.projectData.projects,
    timeActivities: state.timeActivityData.timeActivities,
    financeActivities: state.financeActivityData.financeActivities,
  }),
  mapDispatchToProps: { loadProjectData },
  component: ProjectsPage,
});
