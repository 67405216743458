import React, { useState } from "react";
import { IonRow, IonItemGroup, IonIcon, IonCol } from "@ionic/react";
import {
  chevronDownCircleOutline,
  chevronUpCircleOutline,
} from "ionicons/icons";
import {
  ProjectTimePerTaskGridData,
  //ShoppingList,
  //ShoppingCompareItemModel,
} from "../../models/Project";
//import { User } from "../../models/User";
import ProjectSubTaskTimeGridList from "./ProjectSubTaskTimeGridList";
import { connect } from "../../data/connect";
import { formatDuration } from "../../utils/dateTimeFormat";

interface OwnProps {
  projectTimePerTaskGridData: ProjectTimePerTaskGridData;
}

interface StateProps {
  //projectTimePerTaskGridData: ProjectTimePerTaskGridData[];
  //user: User;
}

interface DispatchProps {}

interface ProjectTaskTimeGridItemProps
  extends OwnProps,
    StateProps,
    DispatchProps {}

const ProjectTaskTimeGridItem: React.FC<ProjectTaskTimeGridItemProps> = ({
  projectTimePerTaskGridData,
  //user,
}) => {
  const [showSubTaskList, setShowSubTaskList] = useState(false);

  const showShoppingCompareLines = () => {
    setShowSubTaskList(!showSubTaskList);
  };

  /*console.log(projectTimePerTaskGridData);
  console.log(
    `${projectTimePerTaskGridData.activity} : ${projectTimePerTaskGridData.totalHours}`
  );*/

  return (
    <IonItemGroup>
      <IonRow>
        <IonCol size="1">
          <IonIcon
            icon={
              showSubTaskList
                ? chevronUpCircleOutline
                : chevronDownCircleOutline
            }
            onClick={() => showShoppingCompareLines()}
          />
        </IonCol>
        <IonCol size="7">{projectTimePerTaskGridData.activity}</IonCol>
        <IonCol size="4">
          {formatDuration(projectTimePerTaskGridData.totalHours)}
        </IonCol>
      </IonRow>
      <ProjectSubTaskTimeGridList
        show={showSubTaskList}
        activity={projectTimePerTaskGridData.activity}
      />
    </IonItemGroup>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    //projectTimePerTaskGridData: state.projectData.projectTimePerTaskGridData,
    //user: state.user.user,
  }),
  mapDispatchToProps: {},
  component: React.memo(ProjectTaskTimeGridItem),
});
