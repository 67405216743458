import { IonItemGroup, IonList, IonListHeader } from "@ionic/react";
import React from "react";
import { ProjectTimePerSubTaskGridData } from "../../models/Project";
import ProjectSubTaskTimeGridItem from "./ProjectSubTaskTimeGridItem";
import { connect } from "../../data/connect";

interface OwnProps {
  activity: string;
  show: boolean;
}

interface StateProps {
  subTaskData: ProjectTimePerSubTaskGridData[];
}

interface DispatchProps {}

interface ProjectSubTaskTimeGridListProps
  extends OwnProps,
    StateProps,
    DispatchProps {}

const ProjectSubTaskTimeGridList: React.FC<ProjectSubTaskTimeGridListProps> = ({
  activity,
  show,
  subTaskData,
}) => {
  if (subTaskData.length === 0 && show) {
    return (
      <IonList>
        <IonListHeader>No Sub-tasks Found</IonListHeader>
      </IonList>
    );
  }

  return (
    <>
      <IonList style={!show ? { display: "none" } : {}}>
        {subTaskData
          .filter((subTask) => subTask.activity === activity)
          .map((subTaskItem) => (
            <IonItemGroup key={`group-${subTaskItem.description}`}>
              <ProjectSubTaskTimeGridItem
                subTaskItem={subTaskItem}
              ></ProjectSubTaskTimeGridItem>
            </IonItemGroup>
          ))}
      </IonList>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    subTaskData: state.projectData.projectTimePerSubTaskGridData,
  }),
  mapDispatchToProps: {},
  component: ProjectSubTaskTimeGridList,
});
