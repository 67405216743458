import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import "./Home.scss";

import { connect } from "../data/connect";
import { RouteComponentProps } from "react-router";
import { Footer } from "../components/layout/Footer";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {}

interface StateProps {}

interface HomeProps extends OwnProps, DispatchProps, StateProps {}

const Impressum: React.FC<HomeProps> = ({}) => {
  //const { name } = useParams<{ name: string }>();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Metric Soup</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <br />
        <IonTitle size="large">
          <h1>Legal disclosure/Impressum</h1>
        </IonTitle>
        <br />
        <p className="styles.EmailListForm.Text">
          www.metricsoup.app is developed and run by <br />
          H Janse van Rensburg <br />
          Cecilienstr 195 <br />
          12683 <br />
          Berlin <br />
          Germany <br />
          <br />
          Email:{" "}
          <a href="mailto:info@metricsoup.app">info@metricsoup.app</a>
        </p>
        <br />
        <br />
      </IonContent>
      <Footer hide={false} />
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: Impressum,
});
