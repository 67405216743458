import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import {
  IonButton,
  IonItem,
  IonLabel,
  IonTitle,
  IonProgressBar,
} from "@ionic/react";
import {
  ChartDataset,
  ChartData,
  ChartDateProjectParameters,
} from "../../models/ChartObjects";
import {
  getProjectProfitLossChartData,
  setProjectProfitLossChartLoading,
} from "../../data/projects/projects.actions";
import { connect } from "../../data/connect";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineController,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData as ChartJSData,
  //ChartTypeRegistry,
} from "chart.js";
import { Chart, Line } from "react-chartjs-2";
import { EmbedChartControl } from "../layout/EmbedChartControl";

interface OwnProps {
  selectionStart: String;
  selectionEnd: String;
  projectId: String;
}

interface StateProps {
  projectProfitLossChartData: ChartData;
  projectProfitLossChartLoading?: boolean;
  userId: string;
}

interface DispatchProps {
  getProjectProfitLossChartData: typeof getProjectProfitLossChartData;
  setProjectProfitLossChartLoading: typeof setProjectProfitLossChartLoading;
}

interface ProjectProfitLossChartProps
  extends OwnProps,
    StateProps,
    DispatchProps {}

const ProjectProfitLossChart: React.FC<ProjectProfitLossChartProps> = ({
  selectionStart,
  selectionEnd,
  projectId,
  projectProfitLossChartData,
  projectProfitLossChartLoading,
  userId,
  getProjectProfitLossChartData,
  setProjectProfitLossChartLoading,
}) => {
  const [labels, setLabels] = useState<String[]>([]);
  const [datasets, setDatasets] = useState<ChartDataset[]>([]);
  const [chartData, setChartData] = useState<ChartJSData[]>([]);
  //const [loading, setLoading] = useState(false);
  const chartRef = useRef<ChartJS>(null);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineController,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  //var loading = false;
  //console.log(loading);

  //var data = [] as Number[];

  useEffect(() => {
    //setLoading(true);
    const chart = chartRef.current;

    getData();
    //setLoading(false);
    setProjectProfitLossChartLoading(false);
  }, [projectProfitLossChartData, projectId]);

  useLayoutEffect(() => {
    if (chartRef !== null && chartRef.current !== null) {
      //chartRef.current.chartInstance.update();
      chartRef.current.update();
    }
  }, [datasets, labels]);

  var dynamicColors = function (alpha: number) {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    //var a = Math.random() * 1;
    return "rgb(" + r + "," + g + "," + b + ", " + alpha + ")";
  };

  const refreshData = async () => {
    //setLoading(true);
    await setProjectProfitLossChartLoading(true);
    let chartDates = {
      startDate: selectionStart,
      endDate: selectionEnd,
      projectId: projectId,
    } as ChartDateProjectParameters;
    await getProjectProfitLossChartData(chartDates);
    //setLoading(false);
  };

  const getData = async () => {
    setLabels(projectProfitLossChartData.labels);
    setDatasets(projectProfitLossChartData.datasets);

    //let data = {} as ChartJSData;

    if (chartRef !== null && chartRef.current !== null) {
      chartRef.current.update();
      chartRef.current.resize();
    }
  };

  const dataTest = {
    //"line",
    //type: "line" as const,
    //type: "line" as ChartTypeRegistry,
    datasets: datasets,
    labels: labels,
  }; //as ChartJSData;

  //console.log(projectProfitLossChartData);
  const datasetsJS = [] as ChartDataset[];

  datasets.forEach((ds) => {
    datasetsJS.push({
      label: ds.label,
      data: ds.data,
      borderColor: ds.borderColor ? ds.borderColor : "rgb(53, 162, 235)",
      backgroundColor: ds.backgroundColor
        ? ds.backgroundColor
        : "rgba(53, 162, 235, 0.5)",
    } as ChartDataset);
  });

  const dataJS = {
    labels,
    datasets: datasetsJS /*[
      {
        label: datasets[0] ? datasets[0].label : "",
        data: datasets[0] ? datasets[0].data : "",
        borderColor: datasets[0]
          ? datasets[0].borderColor
          : "rgb(53, 162, 235)",
        backgroundColor: datasets[0]
          ? datasets[0].backgroundColor
          : "rgba(53, 162, 235, 0.5)",
      },
    ],*/,
  } as ChartJSData;

  //setChartData({datasets, labels});

  const options = {
    title: {
      text: "Profit loss from " + selectionStart + " to " + selectionEnd,
      display: true,
    },
    responsive: true,
    //maintainAspectRatio: false,
    //aspectRatio: 0.5,
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
          labelString: "Currency",
        },
      ],
    },
    animation: {
      duration: 0,
    },
  };

  return (
    <div /*style={{position: "relative", height:"40vh", width:"80vw"}}*/>
      <IonItem color="secondary">
        <IonTitle className="ion-text-wrap">Profit/Loss</IonTitle>
        <EmbedChartControl
          type="openProjectProfitLossChart"
          userId={userId}
          projectId={projectId}
          chartType="line"
        />
        <IonButton
          size="small"
          shape="round"
          onClick={async () => await refreshData()}
        >
          <IonLabel>Change colours</IonLabel>
        </IonButton>
      </IonItem>
      {projectProfitLossChartLoading ? (
        <IonItem>
          <IonTitle>Loading...</IonTitle>
          <IonProgressBar color="primary" type="indeterminate"></IonProgressBar>
        </IonItem>
      ) : (
        <Chart type="line" data={dataJS} /*options={options}*/ redraw></Chart>
      )}
    </div>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    projectProfitLossChartData: state.projectData.projectProfitLossChartData,
    projectProfitLossChartLoading:
      state.projectData.projectProfitLossChartLoading,
    userId: state.user.user._id,
  }),
  mapDispatchToProps: {
    getProjectProfitLossChartData,
    setProjectProfitLossChartLoading,
  },
  component: React.memo(ProjectProfitLossChart),
});
