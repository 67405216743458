import React, { useState, useEffect } from "react";
import {
  IonModal,
  IonButton,
  IonContent,
  IonLabel,
  IonItem,
  IonInput,
  IonToast,
  IonCheckbox,
} from "@ionic/react";
import { connect } from "../../data/connect";
import { Project } from "../../models/Project";
import { addNewProject } from "../../data/projects/projects.actions";
import { allowedToAddProject } from "../../data/premiumChecks";
import PremiumAlert from "../layout/PremiumAlert";

interface OwnProps {
  show: boolean;
  onCreated: (project: Project) => void;
}

interface StateProps {
  projects: Project[];
  premium: boolean;
}

interface DispatchProps {
  addNewProject: typeof addNewProject;
}

//interface CreateItemProps extends OwnProps, StateProps, DispatchProps {}
type CreateProjectProps = OwnProps & StateProps & DispatchProps;

export const CreateProjectModal: React.FC<CreateProjectProps> = ({
  show,
  onCreated,
  projects,
  premium,
  addNewProject,
}) => {
  //const [showModal, setShowModal] = useState(false);
  const [project, setProject] = useState<Project>({
    _id: "",
    userId: "",
    name: "",
    description: "",
    enableEmbedding: false,
    totalHours: 0,
    totalIncome: 0,
    totalExpenses: 0,
  });

  //const id = item.id;
  const name = project.name;
  const description = project.description;
  const enableEmbedding = project.enableEmbedding;

  const [showModal, setShowModal] = useState(show);
  const [showToastCreated, setShowToastCreated] = useState(false);
  const [showPremiumAlert, setShowPremiumAlert] = useState(false);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const onChange = (fieldName: string, fieldValue: string) => {
    setProject({ ...project, [fieldName]: fieldValue });
  };

  const onChangeEnabled = (fieldName: string, fieldValue: boolean) => {
    setProject({ ...project, [fieldName]: fieldValue });
  };

  const onPremiumAlertClose = () => {
    setShowPremiumAlert(false);
  };

  const insertProject = async () => {
    if (allowedToAddProject(projects.length, premium) === true) {
      await addNewProject(project);
      setShowToastCreated(true);
      setTimeout(() => {
        setShowModal(false);
        onCreated(project);
        setProject({
          _id: "",
          userId: "",
          name: "",
          description: "",
          enableEmbedding: false,
          totalHours: 0,
          totalIncome: 0,
          totalExpenses: 0,
        });
      }, 2000);
    } else {
      setShowPremiumAlert(true);
    }
  };

  return (
    <IonContent>
      <IonModal isOpen={showModal} id="create-project-modal">
        <p>Please enter the details of your new project</p>
        <IonItem>
          <IonLabel>Name:</IonLabel>
          <IonInput
            value={name}
            placeholder="Enter the project name"
            onIonChange={(e) => onChange("name", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel> Description:</IonLabel>
          <IonInput
            value={description}
            placeholder="Enter the project description"
            onIonChange={(e) => onChange("description", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel> Enable embedding:</IonLabel>
          <IonCheckbox
            value={enableEmbedding}
            placeholder="Is the project allowed to be embedded?"
            onIonChange={(e) =>
              onChangeEnabled("enableEmbedding", e.detail.checked)
            }
          ></IonCheckbox>
        </IonItem>
        <IonItem>
          <IonButton onClick={async () => await insertProject()} slot="end">
            Create Project
          </IonButton>
          <IonButton onClick={() => setShowModal(false)} slot="end">
            Close
          </IonButton>
        </IonItem>
        <IonToast
          isOpen={showToastCreated}
          onDidDismiss={() => setShowToastCreated(false)}
          message="Your project has been created."
          duration={1500}
          color="primary"
        />
      </IonModal>
      <PremiumAlert
        show={showPremiumAlert}
        onClosed={onPremiumAlertClose}
        type="projects"
      />
    </IonContent>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    premium: state.user.user.premium,
    projects: state.projectData.projects,
  }),
  mapDispatchToProps: { addNewProject },
  component: CreateProjectModal,
});
